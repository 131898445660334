import {
  apartmentCondosLots,
  commercialStyleOfLots,
  Condos,
  InvestmentRange,
  LotsProduct,
  residentialStyleOfLots,
  SingleFamily,
  singleFamilyLots,
  TownHouse,
  townHousePadsLots,
} from './constants';

function intersection(array1: string[], array2: string[]): string[] {
  return array1 && array2 && array1.filter((value) => array2.includes(value));
}

function createTagArray(
  arrayMap: {
    conditionArray: string[];
    valueArray: string[];
  }[],
  propertyArray: string[],
  initialValues: string[] = [],
): string[] {
  return arrayMap.reduce((finalValue, currentPair) => {
    const { conditionArray, valueArray } = currentPair;
    if (intersection(conditionArray, propertyArray)?.length) {
      return [...new Set([...finalValue, ...valueArray])];
    }
    return finalValue;
  }, initialValues);
}

const InvestmentRangeObject: any = {
  'No Minimum': 0,
  '$100,000 or less': 1,
  '$200,000': 2,
  '$300,000': 3,
  '$400,000': 4,
  '$500,000': 5,
  '$750,000': 6,
  '$1,000,000': 7,
  '$1,500,000': 8,
  '$2,000,000': 9,
  '$2,500,000': 10,
  '$3,000,000': 11,
  '$3,500,000': 12,
  '$4,000,000': 13,
  '$5,000,000': 14,
  '$6,000,000': 15,
  '$7,000,000': 16,
  '$8,000,000': 17,
  '$9,000,000': 18,
  '$10,000,000': 19,
  '$11,000,000': 20,
  '$12,000,000': 21,
  '$13,000,000': 22,
  '$14,000,000': 23,
  '$15-20 Mil': 24,
  '$20-25 Mil': 25,
  '$25-30 Mil': 26,
  '$30-50 Mil': 27,
  '$50-100 Mil': 28,
  '$100-150 Mil': 29,
  '$150-250 Mil': 30,
  '$250-500 Mil': 31,
  '$500 Mil+': 32,
};

const InvestmentArray = Object.keys(InvestmentRangeObject);

const createInvestmentRange = (min: string, max: string): string[] =>
  InvestmentArray.slice(
    InvestmentRangeObject[min],
    InvestmentRangeObject[max] + 1,
  );

export const setSurveyCriteriasHelper = (criteriaData: any) => {
  const surveyNameObj: any = criteriaData.find(
    (elem: any) => elem.criteria === 'surveyname',
  );
  let obj: any;
  if (surveyNameObj?.value === 'Builder Company Survey') {
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaTags')
      ?.value?.replace(/{|}|'|"/g, '');
    let numberAndStyleOfLotsPurchased =
      criteriaData
        .find((elem: any) => elem.criteria === 'numberAndStyleOfLotsPurchased')
        ?.value?.replace(/{|}|'|"/g, '')
        ?.split(',') || [];
    let builderFocusSpecialty =
      criteriaData
        .find((elem: any) => elem.criteria === 'builderFocusSpecialty')
        ?.value?.replace(/{|}|'|"/g, '')
        ?.split(',') || [];
    obj = {
      numberAndStyleOfLotsPurchased: [
        ...numberAndStyleOfLotsPurchased,
        ...builderFocusSpecialty,
      ],
      utahAreaTags: utahAreaTags?.split(','),
    };
  }
  if (surveyNameObj?.value === 'Investor Survey') {
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaInvestmentTags')
      ?.value?.replace(/{|}|'|"/g, '');
    let investmentRange = criteriaData
      .find((elem: any) => elem.criteria === 'investmentRange')
      ?.value?.replace(/{|}|'|"/g, '')
      ?.split(',');
    investmentRange = investmentRange?.reduce((acc: any, item: any) => {
      if (item.includes('$')) {
        acc.push(item);
      } else {
        acc[acc.length - 1] += ',' + item;
      }
      return acc;
    }, []);
    let productYourCompanyInvestIn = criteriaData
      .find(
        (elem: any) =>
          elem.criteria === 'whatRealEstateProductDoesYourCompanyInvestIn',
      )
      ?.value?.replace(/{|}|'|"/g, '')
      ?.split(',');
    console.log('invest', investmentRange, productYourCompanyInvestIn);
    let numberAndStyleOfLots: any = [];
    if (investmentRange && productYourCompanyInvestIn) {
      let styleOfLotsByCondos: any = [];
      let styleOfLotsBySingleFamily: any = [];
      let styleOfLotsByTownhouses: any = [];
      // Apartments/Condos
      if (productYourCompanyInvestIn.includes(LotsProduct.condos)) {
        styleOfLotsByCondos = createTagArray(
          [
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['400k'],
                InvestmentRange['6m'],
              ),
              valueArray: [Condos['2-30']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['500k'],
                InvestmentRange['12m'],
              ),
              valueArray: [Condos['31-60']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['1m'],
                InvestmentRange['14m'],
              ),
              valueArray: [Condos['61-90']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['1.5m'],
                InvestmentRange['20-25m'],
              ),
              valueArray: [Condos['91-120']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['2m'],
                InvestmentRange['30-50m'],
              ),
              valueArray: [Condos['121-200']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['3.5m'],
                InvestmentRange['50-100m'],
              ),
              valueArray: [Condos['201-500']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['8m'],
                InvestmentRange['50-100m'],
              ),
              valueArray: [Condos['500+']],
            },
          ],
          investmentRange,
        );
      }

      // Single Family
      if (productYourCompanyInvestIn.includes(LotsProduct.singleFamily)) {
        styleOfLotsBySingleFamily = createTagArray(
          [
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['200k'],
                InvestmentRange['300k'],
              ),
              valueArray: [SingleFamily['1-3']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['200k'],
                InvestmentRange['1.5m'],
              ),
              valueArray: [SingleFamily['4-15']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['400k'],
                InvestmentRange['3.5m'],
              ),
              valueArray: [SingleFamily['10-35']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['1m'],
                InvestmentRange['5m'],
              ),
              valueArray: [SingleFamily['30-55']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['1.5m'],
                InvestmentRange['6m'],
              ),
              valueArray: [SingleFamily['45-75']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['2m'],
                InvestmentRange['11m'],
              ),
              valueArray: [SingleFamily['65+']],
            },
          ],
          investmentRange,
        );
      }

      // Townhouse
      if (productYourCompanyInvestIn.includes(LotsProduct.townhomes)) {
        styleOfLotsByTownhouses = createTagArray(
          [
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['100k'],
                InvestmentRange['5m'],
              ),
              valueArray: [TownHouse['2-30']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['500k'],
                InvestmentRange['10m'],
              ),
              valueArray: [TownHouse['16-60']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['1.5m'],
                InvestmentRange['14m'],
              ),
              valueArray: [TownHouse['50-90']],
            },
            {
              conditionArray: createInvestmentRange(
                InvestmentRange['2.5m'],
                InvestmentRange['20-25m'],
              ),
              valueArray: [TownHouse['81+']],
            },
          ],
          investmentRange,
        );
      }

      const styleOfLotsByProducts = createTagArray(
        [
          {
            conditionArray: [LotsProduct.commercialOffice],
            valueArray: [LotsProduct.commercialOffice],
          },
          {
            conditionArray: [LotsProduct.commercialRetail],
            valueArray: [LotsProduct.commercialRetail],
          },
          {
            conditionArray: [LotsProduct.storageUnits],
            valueArray: [LotsProduct.storageUnits],
          },
          {
            conditionArray: [LotsProduct.industrialManufacturing],
            valueArray: [LotsProduct.industrialManufacturing],
          },
          {
            conditionArray: [LotsProduct.hospitality],
            valueArray: [LotsProduct.hospitality],
          },

          {
            conditionArray: [LotsProduct.mobileParks],
            valueArray: [LotsProduct.mobileHome],
          },
        ],
        productYourCompanyInvestIn,
      );
      numberAndStyleOfLots = [
        ...styleOfLotsByCondos,
        ...styleOfLotsByProducts,
        ...styleOfLotsBySingleFamily,
        ...styleOfLotsByTownhouses,
      ];
    }
    obj = {
      utahAreaTags: utahAreaTags?.split(','),
    };
    if (numberAndStyleOfLots?.length) {
      obj.numberAndStyleOfLots = numberAndStyleOfLots;
    }
  }
  if (surveyNameObj?.value === 'Developer Company Survey') {
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaTags')
      ?.value?.replace(/{|}|'|"/g, '');
    let developerTags = criteriaData
      .find((elem: any) => elem.criteria === 'developerTags')
      ?.value?.replace(/{|}|'|"/g, '');
    obj = {
      developerTags: developerTags?.split(','),
      utahAreaTags: utahAreaTags?.split(','),
    };
  }
  if (surveyNameObj?.value === 'Land Owner Survey') {
    let propertyLocatedInCity = criteriaData.find(
      (elem: any) => elem.criteria === 'propertyLocatedInCity',
    )?.value;
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaTags')
      ?.value?.replace(/{|}|'|"/g, '');
    obj = {
      propertyLocatedInCity: propertyLocatedInCity?.split(','),
      utahAreaTags: utahAreaTags?.split(','),
    };
  }
  if (surveyNameObj?.value === 'New Build Home Buyer Survey') {
    let newBuildHomeBuyerDesiredCities =
      criteriaData.find(
        (elem: any) => elem.criteria === 'newBuildHomeBuyerDesiredCities',
      )?.value ||
      criteriaData.find(
        (elem: any) => elem.criteria === 'new_build_home_buyer_desired_cities',
      )?.value;
    let houseStyle = criteriaData
      .find((elem: any) => elem.criteria === 'typeStyleOfHome')
      ?.value?.replace(/{|}|'|"/g, '')
      ?.split(',');
    let numberAndStyleOfLots: any = [];
    if (houseStyle?.length) {
      if (houseStyle.includes('Condo')) {
        numberAndStyleOfLots = [
          ...numberAndStyleOfLots,
          ...apartmentCondosLots,
        ];
      }
      if (
        houseStyle.filter((value: any) =>
          ['Rambler', 'Two Story', 'Cabin', 'Patio Home'].includes(value),
        )?.length
      ) {
        numberAndStyleOfLots = [...numberAndStyleOfLots, ...singleFamilyLots];
      }
      if (houseStyle.includes('Townhouse')) {
        numberAndStyleOfLots = [...numberAndStyleOfLots, ...townHousePadsLots];
      }
      if (houseStyle.includes('Mobile Home')) {
        numberAndStyleOfLots = [...numberAndStyleOfLots, 'Mobile Parks'];
      }
    }
    let sizeOfLot = criteriaData
      .find((elem: any) => elem.criteria === 'whatSizeOfLotDoYouWant')
      ?.value?.replace(/{|}|'|"/g, '')
      ?.split(',');
    obj = {
      newBuildHomeBuyerDesiredCities: newBuildHomeBuyerDesiredCities,
    };
    if (numberAndStyleOfLots?.length) {
      obj.numberAndStyleOfLots = numberAndStyleOfLots;
    }
    if (sizeOfLot?.length) {
      obj.lotSize = sizeOfLot;
    }
  }
  if (surveyNameObj?.value === 'I Have a Project Survey') {
    let projectStatus = criteriaData.find(
      (elem: any) => elem.criteria === 'tempProjectDevelopmentStage',
    )?.value;
    let city = criteriaData.find(
      (elem: any) => elem.criteria === 'tempCity',
    )?.value;
    let numberAndStyleOfLotsPurchased =
      criteriaData
        .find((elem: any) => elem.criteria === 'numberAndStyleOfLots')
        ?.value?.replace(/{|}|'|"/g, '')
        ?.split(',') ||
      criteriaData
        .find((elem: any) => elem.criteria === 'desiredNumberAndStyleOfLots')
        ?.value?.replace(/{|}|'|"/g, '')
        ?.split(',');
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaTags')
      ?.value?.replace(/{|}|'|"/g, '');
    let specialProductTypes =
      criteriaData.find((elem: any) => elem.criteria === 'specialProductTypes')
        ?.value?.replace(/{|}|'|"/g, '')?.split(',') ||
      criteriaData.find(
        (elem: any) => elem.criteria === 'desiredSpecialProductTypes')?.value?.replace(/{|}|'|"/g, '')?.split(',');

    obj = {
      projectStatus,
      city,
      numberAndStyleOfLotsPurchased,
      utahAreaTags,
      specialProductTypes,
    };
  }
  if (surveyNameObj?.value === 'Real Estate Agent Client Survey') {
    let utahAreaTags =
      criteriaData
        .find((elem: any) => elem.criteria === 'utahAreaTags')
        ?.value?.replace(/{|}|'|"/g, '')?.split(',') || [];
    let clientPropertyLocatedInCity =
      criteriaData.find(
        (elem: any) => elem.criteria === 'clientPropertyLocatedInCity',
      )?.value ||
      criteriaData.find(
        (elem: any) => elem.criteria === 'client_property_located_in_city',
      )?.value;
    let clientUtahAreaInvestmentTags =
      criteriaData
        .find((elem: any) => elem.criteria === 'clientUtahAreaInvestmentTags')
        ?.value?.split(';') || [];
    obj = {
      utahAreaTags: [...utahAreaTags, ...clientUtahAreaInvestmentTags],
      clientPropertyLocatedInCity,
    };
  }
  if (surveyNameObj?.value === 'Builder Client Survey') {
    let city = criteriaData.find(
      (elem: any) => elem.criteria === 'clientPurchaseCity',
    )?.value;
    obj = {
      city,
    };
  }
  if (surveyNameObj?.value === 'Real Estate Agent Survey') {
    let utahAreaTags = criteriaData
      .find((elem: any) => elem.criteria === 'utahAreaTags')
      ?.value?.replace(/{|}|'|"/g, '');
    let licenseTypeAndFocus = criteriaData
      .find((elem: any) => elem.criteria === 'realEstateLicenseTypeAndFocus')
      ?.value?.replace(/{|}|'|"/g, '')
      ?.split(',');
    let desiredNumberAndStyleOfLots: any = [];
    if (licenseTypeAndFocus?.length) {
      if (licenseTypeAndFocus?.includes('Residential')) {
        desiredNumberAndStyleOfLots = [
          ...desiredNumberAndStyleOfLots,
          ...residentialStyleOfLots,
        ];
      }
      if (licenseTypeAndFocus?.includes('Commercial')) {
        desiredNumberAndStyleOfLots = [
          ...desiredNumberAndStyleOfLots,
          ...commercialStyleOfLots,
        ];
      }
      if (licenseTypeAndFocus?.includes('Land')) {
        desiredNumberAndStyleOfLots = [
          ...residentialStyleOfLots,
          ...commercialStyleOfLots,
        ];
      }
    }
    obj = {
      utahAreaTags: utahAreaTags?.split(','),
    };
    if (desiredNumberAndStyleOfLots?.length) {
      obj.desiredNumberAndStyleOfLots = desiredNumberAndStyleOfLots;
    }
  }
  if (surveyNameObj?.value === 'Water Right Survey') {
    let tempWaterRightUtahAreaTags = criteriaData.find(
      (elem: any) => elem.criteria === 'tempWaterRightUtahAreaTags',
    )?.value;
    let tempCityOfWaterRight = criteriaData.find(
      (elem: any) => elem.criteria === 'tempCityOfWaterRight',
    )?.value;
    obj = {
      utahAreaTags: tempWaterRightUtahAreaTags,
      cities: tempCityOfWaterRight,
    };
  }
  console.log('objjjj', obj);
  return obj;
};
