import { useEffect, useState } from 'react';
import { Button, notification } from 'antd';
import {
  setMapCenterPointValue,
  useCreateParcelBookmarkMutation,
  useCreateProjectBookmarkMutation,
  useDeleteParcelBookmarkByParcelIdMutation,
  useDeleteProjectBookmarkByProjectIdMutation
} from 'app/services';
import { useDispatch } from 'react-redux';
import { ParcelMoreInfoPopup } from './ParcelMoreInfoPopup';
import { ParentProjectMoreInfoPopup } from './ParentProjectMoreInfoPopup';
import { ChildProjectMoreInfoPopup } from './ChildProjectMoreInfoPopup';
import TooltipComponent from 'components/Tooltip';
import BookmarkPopover from 'components/BookmarkPopover';
import ContactMoreInformation from './ContactMoreInformation';
import CompanyMoreInformation from './CompanyMoreInformation';
/**
 * Interface defining the props expected by the MoreInfoPopup component.
 */
export interface MoreInfoPopupProps {
  title: string | undefined;
  allData: any;
  information?: any;
  id_serial?: string;
}
export const MoreInfoPopup = ({ title, allData,
  id_serial, information }:
  MoreInfoPopupProps) => {

  const dispatch = useDispatch()
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [createParcelBookmark] = useCreateParcelBookmarkMutation();
  const [deleteParcelBookmark] = useDeleteParcelBookmarkByParcelIdMutation();

  const [createProjectBookmark] = useCreateProjectBookmarkMutation();
  const [deleteProjectBookmark] = useDeleteProjectBookmarkByProjectIdMutation()
  const [projectExistsInBookmarks, setProjectExistsInBookmarks] = useState(false);
  const [isChildProject, setIsChildProject] = useState(false)
  /**
   * Effect hook to determine if the component should render as a child project based on
   * localStorage values.
   * Sets the state of `isChildProject` to `true` if either 'showingChildProjectOnMap' or
   * 'parentProjectsData' is found in localStorage.
   */
  useEffect(() => {
    if (localStorage.getItem('showingChildProjectOnMap') ||
      localStorage.getItem('parentProjectsData')) {
      setIsChildProject(true)
    }
  }, [])

  useEffect(() => {
    if (allData && (allData.type === 'Parcel' ||
      allData.type === 'Project' || allData.type === 'Projects')) {
      setIsChildProject(true)
    }
  }, [])// eslint-disable-line react-hooks/exhaustive-deps
  /**
   * Function to display a modal and set the map center point based on the provided data.
   * If `allData` contains `geom_center` or `geomCenter` coordinates, it updates the map center point
   * using Redux dispatch actions. Then, it sets the state to make the modal visible.
   */
  const showModal = () => {
    if (allData?.geom_center?.coordinates) {
      dispatch(setMapCenterPointValue({
        lat: allData.geom_center.coordinates[1],
        lng: allData.geom_center.coordinates[0],
      }))
    }
    if (allData?.geomCenter?.coordinates) {
      dispatch(setMapCenterPointValue({
        lat: allData.geomCenter.coordinates[1],
        lng: allData.geomCenter.coordinates[0],
      }))
    }
    setIsModalVisible(true);
  };
  /**
   * Function to create a bookmark for either a Parcel or a Project based on the `title`.
   * Uses appropriate API calls (`createParcelBookmark` or `createProjectBookmark`) depending
   * on the `title` and `allData` properties. Displays a success notification upon bookmark creation.
   */
  const createBookmark = () => {
    if (title === 'Parcel') {
      createParcelBookmark({ parcel_id: id_serial, survey_taker: localStorage.getItem('surveyEmail') }).finally(() => {
        notification.success({
          placement: 'topRight',
          message: `${title} bookmarked successfully!`,
        });
      })
    }
    if (title === 'Project' || title === 'Projects') {
      createProjectBookmark({ project_id: allData?.id, type: allData?.parent_project_id ? 'child' : 'parent', survey_taker: localStorage.getItem('surveyEmail') }).finally(() => {
        notification.success({
          placement: 'topRight',
          message: `${title} bookmarked successfully!`,
        });
      })
    }
  }
  /**
   * Function to delete a bookmark for either a Parcel or a Project based on the `title`.
   * Uses appropriate API calls (`deleteParcelBookmark` or `deleteProjectBookmark`) depending
   * on the `title` and `allData` properties. Displays a success notification upon bookmark deletion.
   */
  const deleteBookmark = () => {
    if (title === 'Parcel') {
      deleteParcelBookmark({ id: id_serial }).finally(() => {
        notification.success({
          placement: 'topRight',
          message: `Bookmark deleted successfully!`,
        });
      })
    }
    if (title === 'Project' || title === 'Projects') {
      deleteProjectBookmark({ id: allData?.id }).finally(() => {
        notification.success({
          placement: 'topRight',
          message: `Bookmark deleted successfully!`,
        });
      })
    }
  }
  /**
   * Component rendering logic based on the title and visibility of a modal.
   * Renders different components and UI elements such as tooltips, bookmark popover,
   * and specific popup modals based on conditions and props.
   */
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: 10 }}>
        <TooltipComponent
          placement='top'
          title='More Information'
          body={<Button type="primary" onClick={showModal}>
            More Information
          </Button>}
        />
        {title !== 'contacts' && title !== 'companies' && title !== 'waterRights' ?
          <BookmarkPopover
            okText='Yes'
            cancelText='No'
            title={information?.from === 'bookmark-drawer' || information?.from === 'child-project' || information?.from === 'parent-project' || ((information?.from === 'matching' || information?.from === 'viewAll') && title === 'Parcel') || ((information?.from === 'matching' || information?.from === 'viewAll') && (title === 'Projects' || title === 'Project')) ? null :
              !isChildProject && (projectExistsInBookmarks || information?.existsInBookmarks || information?.allData?.existsInBookmarks)
                ? 'Remove Bookmark'
                : !isChildProject ?
                  'Add Bookmark' : null}
            onConfirm={information?.from === 'bookmark-drawer' || information?.from === 'child-project' || information?.from === 'parent-project' || ((information?.from === 'matching' || information?.from === 'viewAll') && title === 'Parcel') || ((information?.from === 'matching' || information?.from === 'viewAll') && (title === 'Projects' || title === 'Project')) ? null :
              !isChildProject && (projectExistsInBookmarks || information?.existsInBookmarks || information?.allData?.existsInBookmarks)
                ? deleteBookmark
                : !isChildProject ?
                  createBookmark : null}
            body={<>
              {
                information?.from === 'bookmark-drawer' || information?.from === 'child-project' || information?.from === 'parent-project' || ((information?.from === 'matching' || information?.from === 'viewAll') && title === 'Parcel') || ((information?.from === 'matching' || information?.from === 'viewAll') && (title === 'Projects' || title === 'Project')) ? null :
                  !isChildProject && (projectExistsInBookmarks || information?.existsInBookmarks || information?.allData?.existsInBookmarks)
                    ? < img title='Remove Bookmark' src='bookmark_filled.svg' alt='bookmark-filled' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} />
                    : !isChildProject ?
                      <img title='Add Bookmark' src='bookmark.svg' alt='bookmark' style={{ marginLeft: 15, cursor: 'pointer', height: 15, width: 15 }} /> : null
              }
            </>}
          /> : null}
      </div>
      {title === 'Parcel' && isModalVisible && <ParcelMoreInfoPopup id_serial={id_serial} setIsModalVisible={setIsModalVisible} />}
      {(title === 'Project' || title === 'Projects') && !!!allData?.parent_project_id && isModalVisible && <ParentProjectMoreInfoPopup id_serial={id_serial} allData={allData} setIsModalVisible={setIsModalVisible} setProjectExistsInBookmarks={setProjectExistsInBookmarks} />}
      {(title === 'Project' || title === 'Projects') && !!allData?.parent_project_id && isModalVisible && <ChildProjectMoreInfoPopup id_serial={id_serial} allData={allData} setIsModalVisible={setIsModalVisible} setProjectExistsInBookmarks={setProjectExistsInBookmarks} />}
      {title === 'contacts' && isModalVisible && <ContactMoreInformation isModalVisible={isModalVisible} data={allData} setIsModalVisible={setIsModalVisible} />}
      {title === 'companies' && isModalVisible && <CompanyMoreInformation isModalVisible={isModalVisible} data={allData} setIsModalVisible={setIsModalVisible} />}
    </>
  );
};

export default MoreInfoPopup;
