import { Row, Col, Modal, Empty } from 'antd';
import { useEffect, useState } from 'react';

export interface ContactMoreInformationProps {
  data: string;
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
}
export const ContactMoreInformation = ({ isModalVisible, setIsModalVisible, data }:
  ContactMoreInformationProps) => {

  const [contactData, setContactData] = useState<any>()


  useEffect(() => {
    if (data) {
      setContactData(data)
    }
  }, [data])

  const handleOk = () => {
    setIsModalVisible(false);
    setContactData(null)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setContactData(null)
  };

  return (
    <Modal
      title="Contact"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      style={{ marginTop: 50 }}
      bodyStyle={{ maxHeight: 700, overflowY: 'scroll', fontSize: 13 }}
    >
      {contactData ?
        <>
          <div className="meta-detail">
            <span className="meta-detail-name">Name: </span>
            <span className="meta-detail-value">
              {contactData.name}
            </span>
          </div>

          <Row>
            {contactData?.ownerName ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner Name: </span>
                  <span className="meta-detail-value">
                    {contactData?.ownerName}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.email ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Email: </span>
                  <span className="meta-detail-value">
                    {contactData?.email?.length &&
                      Array.isArray(contactData.email) ?
                      contactData?.email?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a key={elem} href={'mailto:' + elem}>{elem}</a></> : <a key={elem} href={'mailto:' + elem}>{elem}</a>
                      }) : contactData?.email ? <a href={'mailto:' + contactData.email}>{contactData.email}</a> : null}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.workEmail ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Work Email: </span>
                  <span className="meta-detail-value">
                    {contactData?.workEmail}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.address ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Address: </span>
                  <span className="meta-detail-value">{contactData?.address}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.builderFocusSpecialty ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Builder Focus Specialty:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.builderFocusSpecialty}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.country ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Country: </span>
                  <span className="meta-detail-value">{contactData?.country}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.city ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">City: </span>
                  <span className="meta-detail-value">{contactData?.city}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.state ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">State: </span>
                  <span className="meta-detail-value">
                    {contactData?.state && contactData?.state.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.county ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">County: </span>
                  <span className="meta-detail-value">{contactData?.county}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.zip ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Zip: </span>
                  <span className="meta-detail-value">{contactData?.zip}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.company_name ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Company: </span>
                  <span className="meta-detail-value">{contactData?.company_name?.join(', ')}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.Type ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Contact Type: </span>
                  <span className="meta-detail-value">
                    {contactData?.Type &&
                      contactData?.Type.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.createdBy ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Created By: </span>
                  <span className="meta-detail-value">
                    {contactData?.createdBy}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.dateOfBirth ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Date Of Birth: </span>
                  <span className="meta-detail-value">
                    {contactData?.dateOfBirth}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.endProductPriceRanges ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    End Product Price Ranges:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.endProductPriceRanges?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.website ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Website: </span>
                  {contactData.website?.length &&
                    contactData.website?.
                      map((elem: any, i: any) => {
                        return i > 0 ? <>, <a target='blank' key={elem}
                          href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                      })}
                </div>
              </Col>
            ) : null}{' '}
            {contactData?.facebookUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Facebook Url: </span>
                  <span className="meta-detail-value">
                    {contactData?.facebookUrl?.length &&
                      contactData?.facebookUrl?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a target='blank' key={elem} href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                      })}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.twitterUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Twitter Url: </span>
                  <span className="meta-detail-value">
                    {contactData?.twitterUrl?.length &&
                      contactData?.twitterUrl?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a target='blank' key={elem} href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                      })}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.fax ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Fax: </span>
                  <span className="meta-detail-value">{contactData?.fax}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.garageSize ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Garage Size: </span>
                  <span className="meta-detail-value">
                    {contactData?.garageSize}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.homeBuyerPriceRange ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Home Buyer Price Range:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.homeBuyerPriceRange}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.howManyAcresIsYourLand ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    How Many Acres Is Your Land:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.howManyAcresIsYourLand}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.howManyBeds ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">How Many Beds: </span>
                  <span className="meta-detail-value">
                    {contactData?.howManyBeds}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.hsLeadStatus ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Hs Lead Status: </span>
                  <span className="meta-detail-value">
                    {contactData?.hsLeadStatus &&
                      contactData?.hsLeadStatus.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.investmentRange ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Investment Range: </span>
                  <span className="meta-detail-value">
                    {contactData?.investmentRange.join()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.jobtitle ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Job Title: </span>
                  <span className="meta-detail-value">{contactData?.jobtitle}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.landOwnerGeneralZoningQuestion ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Land Owner General Zoning Question:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.landOwnerGeneralZoningQuestion}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.marketSellBulk ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Market Sell Bulk: </span>
                  <span className="meta-detail-value">
                    {contactData?.marketSellBulk}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.lotSize?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Lot Size: </span>
                  <span className="meta-detail-value">
                    {contactData?.lotSize?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.desiredLotSizes?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Desired Lot Size: </span>
                  <span className="meta-detail-value">
                    {contactData?.desiredLotSizes?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.mobilephone ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Mobile Phone: </span>
                  <span className="meta-detail-value">
                    {contactData?.mobilephone}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.phone ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">PhoneNumber: </span>
                  <span className="meta-detail-value">{contactData?.phoneNumber?.length &&
                    contactData?.phoneNumber?.map((elem: any, i: any) => {
                      return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                    })}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.phoneNumber1 ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Phone Number1: </span>
                  <span className="meta-detail-value">
                    {contactData?.phoneNumber1?.length &&
                      contactData?.phoneNumber1?.map((elem: any, i: any) => {
                        return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></> : <a key={elem} href={'tel:' + elem}>{elem}</a>
                      })}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.phoneNumber2 ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Phone Number2: </span>
                  <span className="meta-detail-value">
                    {contactData?.phoneNumber2?.length &&
                      contactData?.phoneNumber2?.
                        map((elem: any, i: any) => {
                          return i > 0 ? <>, <a key={elem} href={'tel:' + elem}>{elem}</a></>
                            : <a key={elem} href={'tel:' + elem}>{elem}</a>
                        })}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.projects ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Projects: </span>
                  <span className="meta-detail-value">
                    {contactData?.projects && contactData?.projects.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.websiteProjectsUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Website Projects Url:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.websiteProjectsUrl?.length &&
                      contactData?.websiteProjectsUrl?.
                        map((elem: any, i: any) => {
                          return i > 0 ? <>, <a target='blank' key={elem} href={elem}>{elem}</a></> : <a target='blank' key={elem} href={elem}>{elem}</a>
                        })}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.realEstateLicenseTypeAndFocus ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Real Estate License Type And Focus:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.realEstateLicenseTypeAndFocus &&
                      contactData?.realEstateLicenseTypeAndFocus.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.numberAndStyleOfLots?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Numnber And Style Of Lots: </span>
                  <span className="meta-detail-value">
                    {contactData?.numberAndStyleOfLots?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.desiredNumberAndStyleOfLots?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Desired Numnber And Style Of Lots: </span>
                  <span className="meta-detail-value">
                    {contactData?.desiredNumberAndStyleOfLots?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.tempLandOwnerNumberAndStyleOfLots ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Temp Land Owner Number And Style Of Lots:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.tempLandOwnerNumberAndStyleOfLots &&
                      contactData?.tempLandOwnerNumberAndStyleOfLots.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.typeStyleOfHome ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Type Style Of Home: </span>
                  <span className="meta-detail-value">
                    {contactData?.typeStyleOfHome &&
                      contactData?.typeStyleOfHome.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.utahAreaInvestmentTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Utah Area Investment Tags:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.utahAreaInvestmentTags &&
                      contactData?.utahAreaInvestmentTags.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.vendorId ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Vendor Id: </span>
                  <span className="meta-detail-value">{contactData?.vendorId}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.newBuildHomeBuyerDesiredCities ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    New Build Home Buyer Desired Cities:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.newBuildHomeBuyerDesiredCities}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.numberAndStyleOfLotsPurchased ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Number And Style Of Lots Purchased:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.numberAndStyleOfLotsPurchased}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.numberOfBathrooms ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Number Of Bathrooms:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.numberOfBathrooms}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.ownerTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner Tags: </span>
                  <span className="meta-detail-value">
                    {contactData?.ownerTags}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.parcels ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Parcels: </span>
                  <span className="meta-detail-value">{contactData?.parcels}</span>
                </div>
              </Col>
            ) : null}
            {contactData?.utahAreaTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Utah Area Tags: </span>
                  <span className="meta-detail-value">
                    {contactData?.utahAreaTags &&
                      contactData?.utahAreaTags.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.developerTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Developer Tags: </span>
                  <span className="meta-detail-value">
                    {contactData?.developerTags &&
                      contactData?.developerTags.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.whatRealEstateProductDoesYourCompanyInvestIn ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    What Real Estate Product Does Your Company Invest In:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.whatRealEstateProductDoesYourCompanyInvestIn}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.whatSizeOfLotDoYouWant ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    What Size Of Lot Do You Want:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.whatSizeOfLotDoYouWant}
                  </span>
                </div>
              </Col>
            ) : null}
            {contactData?.project_status ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Project Status:
                  </span>
                  <span className="meta-detail-value">
                    {contactData?.project_status}
                  </span>
                </div>
              </Col>
            ) : null}
          </Row>
        </> : <div className="popupEmptyMsg">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>}
    </Modal>
  );
};

export default ContactMoreInformation;
