import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

// const normalizeKey = (key: any) => key.replace(/[\s/]/g, '_');
const toReadableText = (str: any) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space before capital letters
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize each word
};

const workbook = new ExcelJS.Workbook();
const worksheet1 = workbook.addWorksheet('Summary');
const worksheet2 = workbook.addWorksheet('Full Valuation');

const constructTable = (title: any, subTitle: any, data: any[]) => {
  worksheet2.addRow([]);
  let currentRow = worksheet2.rowCount + 1; // Ensure we start at the correct row

  // console.log('Starting row:', currentRow); // Debugging

  // Add Title
  if (title) {
    worksheet2.mergeCells(`A${currentRow}:M${currentRow}`);
    const cell = worksheet2.getCell(`A${currentRow}`);
    cell.value = title;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true, size: 16 };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '92cddc' },
    };

    currentRow = worksheet2.rowCount + 1; // Update row number
  }

  // Add SubTitle
  if (subTitle) {
    worksheet2.mergeCells(`A${currentRow}:M${currentRow}`);
    const cell = worksheet2.getCell(`A${currentRow}`);
    cell.value = subTitle;
    cell.alignment = { horizontal: 'center', vertical: 'middle' };
    cell.font = { bold: true, size: 12 };
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'c4d79b' },
    };

    currentRow = worksheet2.rowCount + 1;
  }

  if (data?.length > 0) {
    const allFields = Object.keys(data[0]).filter(
      (key) => key !== 'architecturalstyle',
    );
    worksheet2.columns = [{ key: 'A', width: 20 }].concat(
      data.map((_, index) => ({
        key: String.fromCharCode(66 + index),
        width: 20,
      })),
    );
    // Headers
    const headerRow = worksheet2.getRow(currentRow);
    headerRow.values = [''].concat(data.map((d) => d.architecturalstyle));
    headerRow.eachCell((cell) => {
      cell.alignment = { horizontal: 'left' }; // Align headers
      cell.font = { bold: true };
    });

    currentRow = worksheet2.rowCount + 1; // Ensure row count updates correctly

    // Data Rows
    allFields.forEach((field) => {
      const row = worksheet2.getRow(currentRow);
      row.values = [toReadableText(field)].concat(
        data.map((d) => d[field] || '-'),
      );
      row.eachCell((cell) => {
        cell.alignment = { horizontal: 'left' };
      });

      // console.log('Writing row:', row.values); // Debugging

      currentRow = worksheet2.rowCount + 1;
    });
  } else {
    worksheet2.addRow([]);
    worksheet2.addRow([]);
    worksheet2.addRow([]);
  }
};

export const MarketStatsDownload = async (
  summary: any,
  fullValuation: any,
  fileName: any,
) => {
  //   worksheet.columns = [
  //     { header: 'Name', key: 'name', width: 20 },
  //     { header: 'Age', key: 'age', width: 10 },
  //     { header: 'Email', key: 'email', width: 30 },
  //   ];
  const columns = [];
  for (let item in summary[0]) {
    // pascalToNormalText
    const withSpaces = item
      .replace(/_/g, ' ') // Replace underscores with spaces
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Add space between lowercase and uppercase
      .replace(/([A-Z]+)([A-Z][a-z])/g, '$1 $2') // Add space between acronym and capitalized word
      .trim();

    const header =
      withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1).toLowerCase();
    columns.push({
      header,
      key: item,
    });
  }
  worksheet1.columns = columns;
  worksheet1.addRows(summary);

  // Style the header row
  const headerRow = worksheet1.getRow(1); // Header row is usually row 1
  headerRow.eachCell((cell) => {
    cell.font = { bold: true }; // Make bold and increase font size
  });

  // Full valuation data
  // ----------------------

  console.log('fullValuationfullValuation', fullValuation);
  if (fullValuation) {
    constructTable(
      'New Builds',
      'Active New Builds',
      fullValuation.activeNewBuilds,
    );
    constructTable(
      null,
      'Under Contract New Builds',
      fullValuation.underContractNewBuilds,
    );
    constructTable(null, 'Sold New Builds', fullValuation.soldNewBuilds);
    constructTable(
      null,
      'Canceled/Expired/Others New Builds',
      fullValuation.otherNewBuilds,
    );
    constructTable(
      'Existing Builds',
      'Active Existing Builds',
      fullValuation.activeExistingBuilds,
    );
    constructTable(
      null,
      'Under Contract Existing Builds',
      fullValuation.underContractExistingBuilds,
    );
    constructTable(
      null,
      'Sold Existing Builds',
      fullValuation.soldExistingBuilds,
    );
    constructTable(
      null,
      'Canceled/Expired/Others Existing Builds',
      fullValuation.otherExistingBuilds,
    );
    constructTable('All Data', 'Active', fullValuation.activeAllBuilds);
    constructTable(
      null,
      'Under Contract',
      fullValuation.underContractAllBuilds,
    );
    constructTable(null, 'Sold', fullValuation.soldAllBuilds);
    constructTable(
      null,
      'Canceled/Expired/Others',
      fullValuation.otherAllBuilds,
    );
  }

  // Generate Excel file
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/octet-stream' });
  saveAs(
    blob,
    `${fileName}_${new Date().toISOString().replace(/[:.]/g, '-')}.xlsx`,
  );
};
