/* eslint-disable react-hooks/exhaustive-deps */
import { Spin, Tabs, Typography } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { useGetParcelStatsFullValuationQuery, useGetParcelStatsSummaryQuery } from 'app/services'
import { useEffect, useState } from 'react'
import { CopyBlock, dracula } from 'react-code-blocks'
import { stringifySearchObject } from 'utils'

const MarketStatsViewQuery = ({ statsDataFilters }: any) => {

   const Tab = ({ property }: any) => (
      <div className="tab-title">
         <div className="name">
            <span>
               {property}
            </span>
            {/* {!count && <span>{camelCaseToWords(property)}</span>} */}
         </div>
      </div>
   );

   const [activeNewBuildsData, setActiveNewBuildsData] = useState<any>([])

   const {
      data: ParcelStatsSummary,
      isFetching: isParcelStatsSummaryFetching,
   } = useGetParcelStatsSummaryQuery(`?viewQuery=true&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: ActiveNewBuilds,
      isFetching: isActiveNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=New Builds&status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: UnderContractNewBuilds,
      isFetching: isUnderContractNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=New Builds&status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: SoldNewBuilds,
      isFetching: isSoldNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=New Builds&status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: OtherNewBuilds,
      isFetching: isOtherNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=New Builds&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });
   const {
      data: ActiveExistingBuilds,
      isFetching: isActiveExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=Existing Builds&status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: UnderContractExistingBuilds,
      isFetching: isUnderContractExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=Existing Builds&status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: SoldExistingBuilds,
      isFetching: isSoldExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=Existing Builds&status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: OtherExistingBuilds,
      isFetching: isOtherExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&buildingType=Existing Builds&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: ActiveAllBuilds,
      isFetching: isActiveAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: UnderContractAllBuilds,
      isFetching: isUnderContractAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: SoldAllBuilds,
      isFetching: isSoldAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   const {
      data: OtherAllBuilds,
      isFetching: isOtherAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?viewQuery=true&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true
   });

   useEffect(() => {
      setActiveNewBuildsData({ ...activeNewBuildsData, activeNewBuilds: ActiveNewBuilds?.query, underContractNewBuilds: UnderContractNewBuilds?.query, soldNewBuilds: SoldNewBuilds?.query, otherNewBuilds: OtherNewBuilds?.query, activeExistingBuilds: ActiveExistingBuilds?.query, underContractExistingBuilds: UnderContractExistingBuilds?.query, soldExistingBuilds: SoldExistingBuilds?.query, otherExistingBuilds: OtherExistingBuilds?.query, activeAllBuilds: ActiveAllBuilds?.query, underContractAllBuilds: UnderContractAllBuilds?.query, soldAllBuilds: SoldAllBuilds?.query, otherAllBuilds: OtherAllBuilds?.query });
   }, [ActiveNewBuilds, UnderContractNewBuilds, SoldNewBuilds, OtherNewBuilds, ActiveExistingBuilds, UnderContractExistingBuilds, SoldExistingBuilds, OtherExistingBuilds, ActiveAllBuilds, UnderContractAllBuilds, SoldAllBuilds, OtherAllBuilds]);

   return (
      <Tabs style={{ marginTop: 15 }}>
         <TabPane
            tab={<Tab property="Summary" />}
            key="Summary"
         >
            <Spin
               style={{ marginTop: 25 }}
               spinning={isParcelStatsSummaryFetching}
               tip='Loading' size="large"
            >
               {ParcelStatsSummary?.query ?
                  <CopyBlock
                     language="sql"
                     text={ParcelStatsSummary?.query}
                     wrapLines
                     theme={dracula}
                  /> : null}
            </Spin>
         </TabPane>
         <TabPane
            tab={<Tab property="Full Valuation" />}
            key="Full Valuation"
         >
            <Tabs style={{ marginTop: 15 }}>
               <TabPane
                  tab={<Tab property="New Builds" />}
                  key="New Builds"
               >
                  <div style={{ marginBottom: 10 }}>
                     <Spin
                        style={{ marginTop: 25 }}
                        spinning={isActiveNewBuildsFetching || isUnderContractNewBuildsFetching || isSoldNewBuildsFetching || isOtherNewBuildsFetching}
                        tip='Loading' size="large"
                     >
                        <Typography.Title className='market-stats-sub-title'>Active New Builds</Typography.Title>
                        {ActiveNewBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={ActiveNewBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Under Contact New Builds</Typography.Title>
                        {UnderContractNewBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={UnderContractNewBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Sold New Builds</Typography.Title>
                        {SoldNewBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={SoldNewBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Canceled/Expired/Others New Builds</Typography.Title>
                        {OtherNewBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={OtherNewBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}
                     </Spin>
                  </div>
               </TabPane>
               <TabPane
                  tab={<Tab property="Existing Builds" />}
                  key="Existing Builds"
               >
                  <div style={{ marginBottom: 10 }}>
                     <Spin
                        style={{ marginTop: 25 }}
                        spinning={isActiveExistingBuildsFetching || isUnderContractExistingBuildsFetching || isSoldExistingBuildsFetching || isOtherExistingBuildsFetching}
                        tip='Loading' size="large"
                     >
                        <Typography.Title className='market-stats-sub-title'>Active Existing Builds</Typography.Title>
                        {ActiveExistingBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={ActiveExistingBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Under Contact Existing Builds</Typography.Title>
                        {UnderContractExistingBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={UnderContractExistingBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Sold Existing Builds</Typography.Title>
                        {SoldExistingBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={SoldExistingBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Canceled/Expired/Others Existing Builds</Typography.Title>
                        {OtherExistingBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={OtherExistingBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}
                     </Spin>
                  </div>
               </TabPane>
               <TabPane
                  tab={<Tab property="All Data" />}
                  key="All Data"
               >
                  <div style={{ marginBottom: 10 }}>
                     <Spin
                        style={{ marginTop: 25 }}
                        spinning={isActiveAllBuildsFetching || isUnderContractAllBuildsFetching || isSoldAllBuildsFetching || isOtherAllBuildsFetching}
                        tip='Loading' size="large"
                     >
                        <Typography.Title className='market-stats-sub-title'>Active All Builds</Typography.Title>
                        {ActiveAllBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={ActiveAllBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Under Contact All Builds</Typography.Title>
                        {UnderContractAllBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={UnderContractAllBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Sold All Builds</Typography.Title>
                        {SoldAllBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={SoldAllBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}

                        <Typography.Title className='market-stats-sub-title mt-20'>Canceled/Expired/Others All Builds</Typography.Title>
                        {OtherAllBuilds?.query ?
                           <CopyBlock
                              language="sql"
                              text={OtherAllBuilds?.query}
                              wrapLines
                              theme={dracula}
                           /> : null}
                     </Spin>
                  </div>
               </TabPane>
            </Tabs>
         </TabPane>
      </Tabs>
   )
}

export default MarketStatsViewQuery