import { ArrowLeftOutlined } from "@ant-design/icons"
import { Col, Row } from "antd"
import { useHistory } from "react-router-dom";
// eslint-disable-next-line
export const TermsAndConditions = () => {
    const { push } = useHistory();

    return (
        <div style={{ paddingLeft: '30px', paddingRight: '30px' }}>
            <Row justify="center" align="middle" style={{ marginTop: 20, marginBottom: 40 }}>
                <Col xs={2} sm={4} md={6} lg={3}>
                    <ArrowLeftOutlined
                        onClick={() => push({ pathname: '/signup' })}
                        style={{ fontSize: 25, cursor: 'pointer' }}
                    />
                </Col>
                <Col xs={20} sm={16} md={12} lg={18} style={{ textAlign: 'center' }}>
                    <span style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Naxxa Terms of Use</span>
                </Col>
                <Col xs={2} sm={4} md={6} lg={3} />
            </Row>
            <Row><Col lg={3}></Col>
                <Col lg={6}> <h2 style={{ fontWeight: 'bold', fontSize: 20 }}>Updated December 04, 2023</h2>
                </Col>
                <Col lg={3}> </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>

                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    Welcome and thank you for your interest in Naxxa
                    and the Naxxa family of businesses (Naxxa, Inc.
                    and its affiliated brands listed in Section 2,
                    collectively, the “Naxxa Companies,” “us,” “our,”
                    or “we” ). By clicking a registration or new account
                    submission button, or by otherwise using our websites,
                    networks, mobile applications, or other services
                    provided by the Naxxa Companies
                    (collectively, the “Services”),
                    or accessing any content provided by us
                    through the Services,
                    you agree to be bound by the following terms of use,
                    as updated from time to time (the “Terms of Use”).
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>1. Naxxa Companies’ Role. </span>The Naxxa Companies, and the Services, may assist you in performing the various tasks in a real estate transaction. However, unless explicitly specified by the terms of use for a particular Service (each a “Product’s Terms”), THE SERVICES ARE NOT INTENDED TO provide you with any financial, real estate, or related advice of any kind. You understand and agree that the Services may include advertisements. To help make the advertisements relevant and useful to you, the Naxxa Companies may serve advertisements based on the information we collect through the Services. See our Privacy Policy for more details.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>A. Naxxa, Inc.</span> Naxxa, Inc. has a real estate broker license in certain states (see Section 21). However, unless provided for in your Product’s Terms, Naxxa, Inc. assumes no responsibility for any result or consequence related directly or indirectly to any action or inaction that you or any consumer takes based on the Services or any other information available through or in connection with the Services.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>2. Naxxa Companies’ Brands and Businesses. </span> We operate the following affiliated brands, any use of any of the Services provided by the following affiliated brands is subject to these Terms of Use: (a) Naxxa; (b) Trulia; (c) Hotpads; (d) StreetEasy; (e) Out East; (f) Bridge Interactive Group; (g) Naxxa Group Marketplace; (h) Mortech; (i) dotloop; (j) Signpost Homes; (k) Naxxa Homes; (l) Naxxa Closing Services; and (m) Naxxa Home Loans. Services offered by each of these companies may be subject to additional terms of use as available on their respective websites, networks, mobile applications, or otherwise.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>A. Affiliated Business Relationship Notice: </span>
                    This is to give you notice of business relationships
                    among the following providers. Naxxa, Inc.
                    (“ZINC,” which operates Naxxa.com and
                    promotes Naxxa Offers, among other programs);
                    Naxxa Group Marketplace, Inc. (“ZGMI”); Naxxa Homes,
                    Inc. (“ZHO”);
                    Naxxa Home Loans, LLC (“ZHL”); Naxxa Insurance Services,
                    Inc. (“ZIS”); Naxxa Closing and Escrow Services CA, Inc
                    (“ZCS CA”); Spruce Land Services LLC (“Spruce”); Spruce
                    Land Services West LLC (“Spruce West”); Spruce Land
                    Services Southwest LLC (“Spruce Southwest”); Spruce
                    Land Services of Louisiana, LLC (“Spruce Louisiana”);
                    and Spruce Land Services of Alabama, LLC (“Spruce Alabama”)
                    are affiliated companies and each may refer to you the
                    services of another. ZINC, ZGMI, ZHO, ZHL, ZIS, ZCS CA,
                    Spruce, Spruce West, Spruce Southwest, Spruce Louisiana,
                    and Spruce Alabama are each indirectly wholly owned
                    by Naxxa Group, Inc. through one or more subsidiaries.
                    Because of these relationships, any referrals of you by
                    any of the Naxxa Companies may provide the referred company,
                    its direct or indirect parent (including Naxxa Group),
                    and our respective employees with a financial or
                    other benefit.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span>B. Naxxa Group Marketplace and Naxxa Home Loans.

                    </span>
                    Except for ZHL, the Naxxa Companies do not
                    provide or utilize
                    any information for use in loan-related decisions, and you
                    acknowledge and agree not to use any information obtained
                    from ZGMI or any other Naxxa Companies’ Services for loan
                    related decisions. If you choose to engage with ZHL,
                    you will be subject to ZHL’s separate Product’s Terms.
                    You can find more information about ZHL’s mortgage
                    lending services at https://www.Naxxahomeloans.com.</p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>

                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>
                        3. Eligibility; Accounts and Registration. </span>
                    You must be at least 18 years of age to use the Services.
                    By agreeing to these Terms of Use, you represent and
                    warrant that: (a) you are at least 18 years of age;
                    (b) you have not previously been suspended or removed
                    from the Services; and (c) your registration and
                    your use of the Services complies with all applicable
                    laws
                    and regulations. To access some features of the Services
                    , you may be required to register for an account and agree
                    to a Product’s Terms, to the extent applicable to that
                    service,
                    which may be incorporated herein or available on a separate
                    Naxxa Companies site. When you register for an account you
                    will be asked to provide us with some information about
                    yourself,
                    such as email address, phone number, or other contact
                    information.
                    You agree that the information you provide is accurate
                    and that
                    you will keep it accurate and up-to-date at all times.
                    When you
                    register, you will be asked to provide a password.
                    You are solely
                    responsible for maintaining the confidentiality of
                    your account
                    and password,
                    and you are responsible for all actions taken via
                    your account.
                    You may not share your user account(s) with others.
                    Unless you have
                    entered into a commercial agreement with us permitting
                    you to use the Services for transactions on
                    behalf of another person, you may
                    use the Services only for transactions on your
                    own behalf.
                </p></Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontWeight: 'bold', fontSize: 18 }}>4. Use of the Services; Restrictions.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold' }}>A. Use of the Services.</span>
                    As long as you comply with these Terms of Use,
                    we grant you a non-exclusive, limited, revocable,
                    personal, non-transferable license to use the
                    Services, and to download and use any App
                    (as defined in Section 4(B) below) on your
                    mobile device in object code form, for your
                    personal use. If you are a real estate or mortgage
                    professional acting in your professional capacity,
                    you may additionally use the Services to provide
                    information, to the extent permitted by applicable
                    law, to your clients and to take actions on behalf
                    of your clients (“Pro Use”). If you use the Services
                    for a Pro Use, you represent and warrant that you have
                    obtained all required authorizations and consents from
                    your client. Except as expressly stated herein, these
                    Terms of Use do not provide you with a license to use,
                    reproduce, distribute, display or provide access to any
                    portion of the Services on third-party web sites or
                    otherwise.
                    The Services may include software for use in connection
                    with the Services. The Services may not be used for
                    transactions in commercial real estate, which includes,
                    without limitation, commercially zoned properties
                    and vacation rentals.</p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold' }}>B. Mobile Applications. </span>
                    To use any mobile application feature
                    of the Services (each, an “App”), you
                    must have a compatible mobile device.
                    We do not warrant that any App will be
                    compatible with your mobile device. You
                    may use mobile data in connection with an
                    App and may incur additional charges from
                    your wireless provider for using an App.
                    You agree that you are solely responsible
                    for any applicable charges. We may update
                    any app and may automatically electronically
                    update the version of any App that you have
                    installed on a mobile device. You consent to
                    all automatic upgrades, and understand that
                    these Terms of Use will apply to all updated
                    versions of an App. Any third-party open
                    source software included in an App is subject
                    to the applicable open source license and may
                    be available directly from the creator under
                    an open source license. These Terms of Use do
                    not apply to your use of software obtained
                    from a third-party source under an open
                    source license.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold' }}>C. Use of Content. </span>
                    Subject to the restrictions set forth in these
                    Terms of Use, you may copy information from the
                    Services without the aid of any automated
                    processes and only as necessary for your
                    personal use or Pro Use to view, save, print,
                    fax and/or e-mail such information. Notwithstanding
                    the foregoing, the aggregate level data provided
                    on the Naxxa Local-Info Pages (the “Aggregate Data”)
                    may be used for non-personal uses, e.g., real estate
                    market analysis. You may display and distribute
                    derivative works of the Aggregate Data
                    (e.g., within a graph), only so long as
                    the Naxxa Companies are cited as a source
                    on every page where the Aggregate Data are
                    displayed, including “Data Provided by Naxxa
                    Group.” Such citation may not include any of
                    our logos without our prior written approval
                    or imply any relationship between you and the
                    Naxxa Companies beyond that the Naxxa Companies
                    are the source of the Aggregate Data. You are
                    prohibited from displaying any other Naxxa
                    Companies’ data without our prior written
                    approval.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold' }}>5. Prohibited Use. BY USING THE SERVICES, YOU AGREE NOT TO:</span>
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>

                    reproduce, modify, distribute, display or
                    otherwise provide access to, create derivative
                    works from, decompile, disassemble, or reverse
                    engineer any portion of the Services, except as
                    explicitly permitted by any Product’s Terms to
                    the extent applicable to that product’s Services;
                    provide/post/authorize a link to any of the Services
                    (including but not limited to an agent profile page)
                    from a third-party website that is not a real
                    estate-related website owned or operated by a
                    real estate or lending professional or institution;
                    remove or modify any copyright or other intellectual
                    property notices that appear in the Services;
                    use the Services in any way that is unlawful,
                    or harms the Naxxa Companies, its service
                    providers, suppliers, affiliates, or any other user;
                    use the Services in any way to discriminate
                    against any individual or class of individuals
                    protected under federal, state or local laws,
                    or which may have a discriminatory impact against
                    any individual or class of individuals, or which
                    otherwise promotes illegal, racist or discriminatory
                    activities or outcomes;
                    distribute or post spam or other unsolicited messages,
                    chain letters, pyramid schemes, or similar
                    communications through the Services;
                    impersonate another person, misrepresent your
                    affiliation with another person or entity, or
                    make any representation to any third party
                    under false pretenses;
                    reproduce, publicly display, or otherwise make
                    accessible on or through any other website, application,
                    or service any reviews, ratings, or profile information
                    about real estate, lending, or other professionals,
                    underlying images of or information about real estate
                    listings, or other data or content available through
                    the Services, except as explicitly permitted by us
                    for a particular portion of the Services;
                    upload invalid data, viruses, worms, or other
                    software agents to the Services;
                    post, reproduce, publicly display, or otherwise
                    make accessible any content, which we, in our
                    sole judgement and discretion, consider illegal,
                    offensive or objectionable including without limitation
                    content that harasses, discriminates, demeans,
                    threatens or disparages any individual or class of
                    individuals;
                    interfere with, or compromise the system integrity
                    or security of the Services, or otherwise bypass any
                    measures we may use to prevent or restrict access
                    to the Services;
                    conduct automated queries (including screen and
                    database scraping, spiders, robots, crawlers,
                    bypassing “captcha” or similar precautions, or any
                    other automated activity with the purpose of obtaining
                    information from the Services) on the Services;
                    use any of the Naxxa Companies’ trademarks as part
                    of your screen name or email address on the Services;
                    access or use any of the Services to develop
                    competitive products or services; or
                    attempt to, or permit or encourage any third
                    party to, do any of the above.
                </p>
                </Col>
            </Row>



            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontWeight: 'bold', fontSize: 18 }}> 6. Fees. </p>
                    <p style={{ fontSize: 18 }}>
                        <span style={{ fontWeight: 'bold', fontSize: 18 }}>A. Generally.</span> You may be required to pay fees to access certain features of the Services. All fees are in U.S. dollars and are non-refundable. If we change the fees for all or part of any of the Services, including by adding fees or charges, we will provide you advance notice of those changes. If you do not accept the changes, we may discontinue providing the applicable part of the Services to you. Our authorized third-party payment processors will charge the payment method you specified at the time of purchase or as agreed to as part of the selected Service. You authorize us to charge all fees as described in these Terms of Use for the Services you select to that payment method. If you pay any fees with a credit card, we may seek pre-authorization of your credit card account before your purchase to verify that the credit card is valid and has the necessary funds or credit available to cover your purchase.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>B. Subscriptions. </span>

                    The Services may include features that allow
                    for automatically recurring payments for periodic
                    charges (“Subscription Service”). If you decide to
                    activate a Subscription Service, you authorize us
                    to periodically charge, on a going-forward basis and
                    until cancellation of either the recurring payments or
                    your account, all accrued sums on or before the payment
                    due date for the accrued sums. The subscription will
                    continue unless and until you cancel your subscription,
                    or we terminate it. You must cancel your Subscription
                    Service before it renews in order to avoid billing of
                    the next periodic subscription fee to your account. We
                    will bill the periodic subscription fee to the payment
                    method you provide to us during registration (or to a
                    different payment method if you change your payment
                    information). We may change the subscription fee for
                    any subsequent subscription period but will provide
                    you advance notice of any increase before it applies.
                    Unless otherwise stated in your Product’s Terms,
                    you may cancel a Subscription Service through the
                    settings page in your account, by contacting us at
                    consumercare@Naxxa.com, or, if applicable
                    by contacting your business consultant.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontWeight: 'bold', fontSize: 18 }}>7. User Materials. </p>

                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}> A. UGC Definition; License Grant. </span>
                    Certain portions of the Services may
                    allow users to upload or otherwise provide
                    the Naxxa Companies images, photos, video,
                    data, text, listings, and other content
                    (“User Materials”). Absent any additional
                    terms provided in your Product’s Terms, by
                    uploading or otherwise providing User Materials
                    to the Services, you grant us an irrevocable,
                    perpetual, royalty-free worldwide license to:
                    (i) use, copy, distribute, transmit, publicly
                    display, publicly perform, reproduce, edit,
                    modify, prepare derivative works of or incorporate
                    into other works, and translate your User
                    Materials, in connection with the Services or
                    in any other media; and (ii) sublicense these
                    rights, to the maximum extent permitted by
                    applicable law. We will not pay you for your
                    User Materials or to exercise any rights
                    related to your User Materials set forth
                    in the preceding sentence. We may remove or
                    modify your User Materials at any time. You
                    are solely responsible for all User Materials
                    made through your user account(s) on the Services
                    or that you otherwise make available through the
                    Services. For all User Materials, you represent
                    and warrant that you are the creator and owner of
                    the User Materials, or have the necessary licenses,
                    rights, consents, and permissions (including all
                    permissions required under applicable privacy and
                    intellectual property law) to authorize the Naxxa
                    Companies and other users to access and use your
                    User Materials as necessary to exercise the licenses
                    granted by you under these Terms of Use. We will not
                    use client contact information that is uploaded by
                    agents into the Naxxa Agent Hub portal for
                    any purpose, unless that client contact
                    information is otherwise provided to us
                    directly by the client.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>B. UGC Disclaimer. </span>
                    We are under no obligation to edit or
                    control your User Materials or the User
                    Materials of any other User and will not
                    be in any way responsible or liable for
                    any User Materials. We may, however, at any
                    time and without prior notice, screen, remove,
                    edit, or block any User content on the Services,
                    including User Materials, that in our sole
                    judgment violates these Terms of Use or we
                    find otherwise objectionable, including any
                    violation of our Good Neighbor Policy, described
                    in Section 13(B). You understand that when using
                    the Services, you may be exposed to User Materials
                    of other users and acknowledge that User Materials
                    may be inaccurate, offensive, indecent, or
                    objectionable. You agree to waive, and do
                    waive, any legal or equitable right or remedy
                    you may have against us with respect to User
                    Materials. We expressly disclaim any and all
                    liability in connection with User Materials.
                    If notified by a user or content owner that
                    User Materials allegedly do not conform with
                    these Terms of Use, we may investigate the
                    allegation and determine in our sole discretion
                    whether to remove those User Materials, which
                    we reserve the right to do at any time and
                    without notice. For more information on our
                    handling of infringing content, please see
                    Section 11 below.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontWeight: 'bold', fontSize: 18 }}> 8. Third Party/Linked Services/Sent information. </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}> <p style={{ fontSize: 18 }}>
                    <span style={{ fontWeight: 'bold', fontSize: 18 }}>A. Generally. </span>
                    The Services include links to third-party products,
                    services, and websites, as well as materials provided
                    by third parties, and may include functionality that
                    allows for the distribution of your User Materials
                    or your personal information (collectively, your
                    “User Information”) to third parties not under our
                    control (each, a “Third-Party Provider”). Third-Party
                    Providers are solely responsible for their services.
                    You are responsible for your use and submission
                    of User Information to any third party,
                    and your dealings or business conducted
                    with any third party arising in connection
                    with the Services are solely between you
                    and such third party. Your use of third-party
                    sites, services, or products may be subject
                    to associated third-party terms of use and
                    privacy policies or other agreements, which
                    you are solely responsible for complying with.
                    We do not endorse, and take no responsibility for
                    such products, services, Web sites, and materials,
                    or a Third-Party Provider’s use of your User Information.
                    By using a tool that allows for User Information to be
                    transferred, you agree that we may transfer the applicable
                    User Information or other information to the applicable
                    third-parties, which are not under our control. If you
                    submit a contact form or otherwise indicate your interest
                    in contacting a Third-Party Provider, you may receive
                    telemarketing calls from the Third-Party Provider using
                    the contact information you provided. Third-Party Providers
                    may keep your contact information and any other information
                    received by the Third-Party Provider in processing a contact
                    or other request form. We are not responsible for any
                    damages
                    or costs of any type arising out of or in any way connected
                    with your dealings with these third parties.
                </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontWeight: 'bold', fontSize: 18 }}>B. Certain Third-Party Services. </p>
                    <p style={{ fontSize: 18 }}>
                        (i) Referrals and Lead Sales. As part of the Services,
                        you may be connected with a real estate professional.
                        You authorize us to make such referral or lead sale
                        and acknowledge that we may be paid valuable
                        consideration for facilitating such connection.
                        As part of the Services, you may be connected
                        to a party that provides mortgage loan
                        origination services, title and escrow services,
                        or other settlement services. If any of these
                        parties have an affiliated relationship with any
                        of the Naxxa Companies, then one or more of the
                        Naxxa Companies may receive a financial or
                        other benefit from the referral between such
                        affiliates</p>

                    <p style={{ fontSize: 18 }}>
                        (ii) Non-Affiliated Financial Products.
                        If you choose to contact a non-affiliated
                        bank, mortgage lender, financial institution,
                        mortgage loan originator, loan broker, or
                        other mortgage professional through the Services
                        by filling out a contact, other request form or
                        otherwise indicating your interest in contacting
                        (or being contacted by) a Third-Party Provider
                        (including requests for mortgage rate quotes)
                        on the Services, you authorize the Naxxa Companies,
                        and Naxxa Group Marketplace, Inc., in particular
                        to provide the information you submit to the
                        Third-Party Provider. If you include your name,
                        contact information and other information in a
                        request, your identity will no longer be anonymous
                        to the Third-Party Provider. Your submission of
                        information and any request for quotes through
                        the Services is not an application for credit.
                        In the case of non-affiliated financial products,
                        the Naxxa Companies are only providing an
                        administrative service to consumers and participating
                        Third-Party Providers. Loan approval standards are
                        established and maintained solely by individual
                        Third-Party Providers. Decisions in any related
                        non-affiliated transactions are made by participating
                        Third-Party Providers and are not made by the Naxxa
                        Companies and the Naxxa Companies are not responsible
                        for the underwriting activities or credit decisions of
                        any Third-Party Provider. Any non-binding quotes
                        provided
                        by the Naxxa Companies for Third-Party Providers’
                        financial products are not intended to be official
                        Loan Estimates as defined in the Real Estate
                        Settlement Procedures Act or the Truth in Lending Act,
                        or to otherwise serve as a disclosure of any
                        specific loan terms or conditions under any
                        state or federal law. Interest rates displayed
                        through the Services are for informational purposes
                        only and reflect non-binding quotes of the terms a
                        Third-Party Provider might offer an applicant fitting a
                        consumer’s anonymous profile. Actual interest rates
                        may vary. Consumers should rely on their own judgment
                        in deciding which available loan product, terms, and
                        Third-Party Provider best suit their needs and financial
                        means. Except as set forth in Section 8(b)(i) above or
                        as otherwise
                        disclosed to you or provided in other
                        Product’s Terms, the Naxxa Companies: (a)
                        are not a Third-Party Provider, loan originator,
                        loan processor or underwriter; (b) do not aid or
                        assist applicants in obtaining loans, soliciting
                        applicants or Third-Party Providers for loans,
                        or offer or negotiate terms of applications or
                        loans; (c) do not take mortgage applications,
                        make loans or credit decisions, or prequalify
                        or preapprove applicants for loans; (d) are
                        not an agent of either any consumer or any
                        Third-Party Provider; (e) do not endorse,
                        refer, or recommend any Third-Party Provider
                        that pays the Naxxa Companies, or the products
                        of any Third-Party Provider that pays the Naxxa
                        Companies; (f) are not responsible for any errors
                        or delays caused by consumers or any Third-Party
                        Provider in the loan process; and (g) do not guarantee
                        offer of, or acceptance into, any particular
                        loan program
                        or specific loan terms, conditions, or rates with any
                        Third-Party Provider, or that any rates or terms will
                        be the best available.
                    </p>
                    <p style={{ fontSize: 18 }}>(iii) Rental Products.
                        If you choose
                        to submit your information in order to submit
                        a background or
                        credit check, you are subject to the CIC and
                        Experian terms
                        as described in Section 8(C) below. We do
                        not process, or
                        store background or credit check information
                        related to our
                        rental products and do not control how a landlord uses
                        background or credit check information in evaluating
                        applications submitted to them. We do not guarantee
                        any acceptance by a landlord of an application but do
                        require all landlords using the Services to comply
                        with the Fair Housing Act and other applicable laws.
                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontSize: 18 }}>

                        <span style={{ fontWeight: 'bold', fontSize: 18 }}> C.Additional Terms
                            for Third Party Services. </span>
                        Certain aspects of the Services include
                        Third - Party tools that are subject to
                        additional Third - Party terms,
                        including, but not limited to, the following:
                    </p>
                    <p style={{ fontSize: 18 }}>
                        (i) Windows Live Virtual Earth.Windows
                        Live Virtual Earth imagery is supplied
                        by Microsoft Corporation, and use is
                        subject to the Microsoft MapPoint Terms
                        of Use available at
                        http://www.microsoft.com/maps/assets/docs/terms.aspx.
                    </p>
                    <p style={{ fontSize: 18 }}>
                        (ii) Google Maps.Some of the Services
                        implement the Google Maps web mapping
                        service.Your use of Google Maps is
                        subject to Google’s terms of use,
                        available at
                        http://www.google.com/intl/en_us/help/terms_maps.html,
                        and Google’s Privacy Policy, available at
                        https://www.google.com/intl/ALL/
                        policies/privacy/index.html.
                    </p>
                    <p style={{ fontSize: 18 }}>

                        (iii) Stripe.Some of the Services
                        allow you to use Stripe Connect to make
                        payments to other users, and may include
                        additional processing or application fees detailed
                        when you choose to connect to Stripe.Your use of Stripe
                        is subject to the Stripe Connected Account Agreement,
                        available at
                        https://stripe.com/us/connect-account/legal.
                        Additionally, by using Stripe, you agree
                        not to use Stripe
                        (and the Services generally) for any Prohibited
                        Business purposes, as listed at
                        https://stripe.com/us/prohibited-businesses.
                    </p>
                    <p style={{ fontSize: 18 }}>
                        (iv) CIC.Some of the Services allow
                        users to use our Third - Party background
                        check partner CIC’s services to submit
                        an application and background check to a
                        landlord.If you are a Renter using the CIC
                        services, you authorize Naxxa to obtain your
                        background check report, including criminal
                        and housing court records and to share that
                        information with Landlords to whom you submit
                        an application, and agree to the CIC Legal and
                        Privacy Policy available at
                        https://www.cicreports.com/legal-and-privacy-policy.
                        If you
                        are a Landlord using the CIC services, you agree
                        to use the background check reports in compliance
                        with the law, and agree to the CIC Legal and
                        Privacy Policy linked above. Further, you
                        understand and acknowledge that the reporting
                        of criminal and housing court records may be
                        restricted in accordance with federal,
                        state and local legislation/regulations on reporting
                        and/or use restrictions, government agency guidance
                        and industry best practices. CIC does not use
                        Artificial Intelligence (AI) in the collection or
                        hygiene of criminal or housing court records.

                    </p>
                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontSize: 18 }}>

                        <span style={{ fontWeight: 'bold', fontSize: 18 }}> 9. Intellectual Property. </span>
                        The Services are owned and operated by the
                        Naxxa Companies.The user interfaces,
                        design, information, data, code, products,
                        software, graphics, and all other elements
                        of the Services(the “Naxxa Companies’
                        Materials”) that we provide are protected
                        by intellectual property and other laws
                        and are the property of the Naxxa Companies
                        or the Naxxa Companies’ Third - Party
                        licensors.Except as expressly allowed by
                        these Terms of Use, you may not make use
                        of the Naxxa Companies’ Materials, and the Naxxa
                        Companies reserve all rights to the Naxxa
                        Companies’ Materials and Services not granted
                        expressly in these Terms of Use.
                    </p>
                    <p style={{ fontSize: 18 }}>
                        Intellectual Property Notices:
                    </p>
                    <p style={{ fontSize: 18 }}>
                        Certain content on the Services
                        is owned by ZIP + 4 data and the United
                        States Postal Service.To view a complete
                        list of our issued patents and active
                        trademarks, click here.The names of
                        actual companies, products, and services
                        mentioned herein may be the trademarks of
                        their respective owners.Any rights not
                        expressly granted herein are reserved.We
                        do not assert copyright or grant any rights
                        to the underlying images or descriptions of
                        real estate listings provided through the
                        Services.Any use of these images and
                        descriptions is subject to the copyright
                        owner’s permission and the requirements
                        of applicable law.
                    </p>

                </Col>
            </Row>
            <Row>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontSize: 18 }}>

                        <span style={{ fontWeight: 'bold', fontSize: 18 }}>10. Feedback. </span>
                        If you choose to provide input
                        and suggestions regarding the Services,
                        including related to any of the Naxxa
                        Companies’ Materials(“Feedback”), then
                        you hereby grant us an unrestricted, perpetual
                        , irrevocable, non - exclusive, fully
                        - paid, royalty - free right to use the
                        Feedback in any manner and for any purpose,
                        including to improve the Services or
                        create other products and services.
                    </p>
                </Col>
            </Row>
            <Row style={{ fontSize: 18 }}>
                <Col lg={3}></Col>
                <Col lg={18}>
                    <p style={{ fontSize: 18 }}>

                        <span style={{ fontWeight: 'bold', fontSize: 18 }}>11. DMCA;Claims of
                            Copyright Infringement. </span>
                        We respect the intellectual property
                        rights of others and ask that everyone
                        using the Services do the same.Anyone who
                        believes that their work has been reproduced
                        on the Services in a way that constitutes
                        copyright infringement may notify our copyright
                        agent in accordance with Title 17, United States
                        Code, Section 512(c)(2), by providing the
                        following information:
                    </p>
                    <p style={{ fontSize: 18 }}> (i) Identification
                        of the copyrighted work that you
                        claim has been infringed;

                    </p>
                    <p style={{ fontSize: 18 }}>
                        (ii) Identification of the material
                        that you claim is infringing and needs
                        to be removed, including a description
                        of where it is located on the Services so
                        that the copyright agent can locate it;
                    </p>
                    <p style={{ fontSize: 18 }}>(iii) Your address,
                        telephone number, and, if available, e -
                        mail address, so that the copyright agent
                        may contact you about your complaint; and
                    </p >
                    <p style={{ fontSize: 18 }}>(iv) A signed statement
                        that the above information is accurate; that you
                        have a good faith belief that the identified use
                        of the material is not authorized by the copyright
                        owner, its agent, or the law; and, under penalty
                        of perjury, that you are the copyright owner or are
                        authorized to act on the copyright owner’s behalf
                        in this situation.
                    </p>
                    <p style={{ fontSize: 18 }}>
                        Notices of copyright infringement
                        claims should be sent as follows:
                    </p>
                    <p>
                        By Mail:<br></br>
                        Naxxa, Inc.<br></br>
                        1301 Second Avenue, Floor 36<br></br>
                        Seattle, WA 98101<br></br>
                        Attention: Copyright Agent
                    </p>
                    <p>
                        By Email:<br></br>
                        If relating to content on Trulia.com and
                        related websites: DMCA_notice_email @trulia.com<br></br>
                        Otherwise: DMCA_notice_email @Naxxa.com
                    </p>
                    <p> If you give notice of copyright infringement
                        by e - mail, we may begin investigating the alleged
                        copyright infringement; however, we must
                        receive your signed statement by mail or
                        as an attachment to your e - mail before
                        we are required to take any action.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        12. Deactivation / Deletion / Changes to Agreement.
                    </span>
                        Except as stated in any Product’s Terms,
                        you may deactivate your account at any time by
                        selecting “deactivate account” in your
                        account settings.You can delete your account
                        and all your account data by submitting a
                        request to “delete your data”
                        at https://privacy.Naxxagroup.com/.
                        If you deactivate or delete your account
                        , you remain obligated to pay all
                        outstanding fees, if any, incurred prior
                        to termination relating to your use of
                        the Services. If you violate any provision
                        of these Terms of Use, your permission from
                        the Naxxa Companies to use the Services will
                        terminate automatically. In addition, we may,
                        in our sole discretion, deactivate, suspend,
                        or terminate your access to your account and
                        the Services at any time for any reason, with
                        or without notice. We may alter, suspend, or
                        discontinue the Services or any portion of the
                        Services without notice. We will not be liable
                        whatsoever for any change to the Services or
                        any suspension or termination of your access
                        to, or use of the Services. We reserve the
                        right to change these Terms of Use at any
                        time in its sole discretion on a going-forward basis,
                        and we will make commercially reasonable efforts to
                        notify you of any material changes to these Terms
                        of Use. Your continued use of the Services after
                        any updates are effective will represent your
                        agreement to the revised version of the Terms
                        of Use and will constitute acceptance of, and
                        agreement to, any such changes. You further
                        waive any right you may have to receive
                        specific notice of such changes to these
                        Terms of Use. You are responsible for
                        regularly reviewing these Terms of Use.

                    </p>
                    <p style={{ fontWeight: 'bold' }}>13. Privacy Policy / Other Terms.</p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        A.Privacy Policy.
                    </span>
                        The Naxxa Companies will collect, use, store,
                        and disclose personal information in accordance
                        with our Privacy Policy.Please consult your
                        Privacy Policy for more information, which
                        is incorporated into, and made a part of,
                        these Terms of Use.

                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        B.Other Terms.
                    </span>
                        Your use of the Services is subject to all
                        additional guidelines, rules, Product Terms,
                        and agreements applicable to the Services or certain
                        features of the Services that we may post on,
                        or link to, from the Services, such as rules

                        applicable to a particular product or
                        content available through the Services,
                        including, without limitation, the Good
                        Neighbor Policy, the Listing Quality Policy,
                        the Self - Tour Terms and Conditions, the
                        Rentals User Terms, the Respectful Renting
                        Pledge, the 3D Home Terms and Guidelines,
                        and the Video Walkthrough Guidelines.If you are
                        a non - affiliated Third - Party Provider
                        who has created an industry professional
                        account with Naxxa Group, you are subject
                        to the NMLS Data Terms.Additionally, if you
                        are a non - affiliated Third - Party Provider
                        who participates in any Services offered by
                        Naxxa Group Marketplace, Inc.you are also
                        subject to the Naxxa Group Market Place,
                        Inc.’s Lender Terms of Use.All such terms
                        are incorporated into, and made a part

                        of, these Terms of Use.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        14. Indemnification.
                    </span>
                        You agree to indemnify, defend,
                        and hold harmless the Naxxa Companies,
                        our affiliates, and our respective
                        directors, officers, employees, and
                        agents from any and all claims and demands
                        made by any third party due to or arising
                        out of: (a) your access to or use of the
                        Services; (b) your breach of these Terms
                        of Use; (c) your violation of any law or
                        the rights of a third party; (d) any dispute
                        or issue between you and any third party; (e)
                        any User Materials you upload to, or otherwise make
                        available through, the Services; (f)
                        your willful misconduct; and(g) any other party’s
                        access to or use of the Services using your
                        account and password.The Naxxa Companies reserve
                        the right, at their own expense, to assume the
                        exclusive defense and control of any matter
                        otherwise subject to indemnification by you,
                        and in that case, you agree to cooperate with
                        the Naxxa Companies’ defense of that claim.
                    </p>

                    <p><span style={{ fontWeight: 'bold' }}>
                        15. No Warranties.
                    </span>
                        THE NAXXA COMPANIES PROVIDE THE SERVICES “AS IS,”
                        “WITH ALL FAULTS” AND “AS AVAILABLE,” AND THE
                        ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE,
                        ACCURACY, AND EFFORT IS WITH YOU.TO THE MAXIMUM EXTENT
                        PERMITTED BY APPLICABLE LAW, THE NAXXA COMPANIES AND OUR
                        SUPPLIERS MAKE NO REPRESENTATIONS, WARRANTIES,

                        OR CONDITIONS, EXPRESS OR IMPLIED.THE NAXXA
                        COMPANIES AND OUR SUPPLIERS EXPRESSLY DISCLAIM
                        ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS,
                        STATUTORY, AND IMPLIED, INCLUDING WITHOUT LIMITATION:
                        (A) WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS
                        FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY,
                        TITLE, QUIET ENJOYMENT, NO ENCUMBRANCES,
                        NO LIENS, AND NON
                        - INFRINGEMENT; (B) WARRANTIES OR CONDITIONS ARISING
                        THROUGH COURSE OF DEALING OR USAGE OF TRADE;
                        AND(C) WARRANTIES
                        OR CONDITIONS OF UNINTERRUPTED OR ERROR -
                        FREE ACCESS OR USE.
                        NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                        OBTAINED
                        BY YOU THROUGH THE SERVICES OR ANY MATERIALS
                        AVAILABLE THROUGH
                        THE SERVICES WILL CREATE ANY WARRANTY REGARDING ANY
                        NAXXA COMPANIES’
                        ENTITY OR THE SERVICES THAT IS NOT EXPRESSLY STATED IN
                        THESE TERMS OF USE.YOU ASSUME ALL RISK FOR ANY DAMAGE
                        THAT MAY RESULT FROM YOUR USE OF OR ACCESS
                        TO THE SERVICES,
                        YOUR DEALING WITH ANY OTHER USER, AND ANY MATERIALS,
                        INCLUDING
                        ALL USER AND NAXXA COMPANIES’ MATERIALS, AVAILABLE
                        THROUGH THE
                        SERVICES.YOU UNDERSTAND AND AGREE THAT
                        YOUR USE OF
                        THE SERVICES, AND USE, ACCESS, DOWNLOAD,
                        OR OTHER OBTAINMENT OF MATERIALS THROUGH
                        THE SERVICES AND ANY ASSOCIATED SITES OR
                        SERVICES, ARE AT YOUR OWN DISCRETION AND
                        RISK, AND THAT YOU ARE SOLELY RESPONSIBLE
                        FOR ANY DAMAGE TO YOUR PROPERTY(INCLUDING
                        YOUR COMPUTER SYSTEM OR MOBILE DEVICE
                        USED IN CONNECTION WITH THE SERVICES), OR
                        THE LOSS OF DATA THAT RESULTS FROM THE USE
                        OF THE SERVICES OR THE DOWNLOAD OR USE OF
                        THOSE MATERIALS.SOME JURISDICTIONS MAY PROHIBIT
                        A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE
                        OTHER RIGHTS THAT VARY FROM JURISDICTION TO
                        JURISDICTION.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        16. Limitation of Liability / Exclusive Remedy.
                    </span>
                        IN NO EVENT WILL THE NAXXA COMPANIES OR
                        ANY OF OUR AFFILIATES BE LIABLE FOR ANY
                        INDIRECT, CONSEQUENTIAL, SPECIAL,
                        INCIDENTAL, OR PUNITIVE DAMAGES(INCLUDING
                        DAMAGES FOR LOSS OF PROFITS, GOODWILL,
                        OR ANY OTHER INTANGIBLE LOSS) ARISING
                        OUT OF, BASED ON, OR RESULTING FROM THESE
                        TERMS OF USE OR YOUR USE OR ACCESS,
                        OR INABILITY TO USE OR ACCESS, THE
                        SERVICES OR ANY MATERIALS ON THE SERVICES,
                        WHETHER BASED ON: (A) BREACH OF CONTRACT;
                        (B) BREACH OF WARRANTY; (C) NEGLIGENCE; OR(D)
                        ANY OTHER CAUSE OF ACTION, EVEN IF THE NAXXA
                        COMPANIES HAVE BEEN ADVISED OF THE
                        POSSIBILITY OF SUCH DAMAGES.TO THE MAXIMUM
                        EXTENT PERMITTED BY APPLICABLE LAW,
                        THE NAXXA COMPANIES ASSUME NO LIABILITY
                        OR RESPONSIBILITY FOR ANY(I) ERRORS, MISTAKES
                        , OR INACCURACIES OF MATERIALS; (II) PERSONAL
                        INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                        WHATSOEVER, RESULTING FROM YOUR ACCESS TO
                        OR USE OF THE SERVICES; (III) ANY UNAUTHORIZED
                        ACCESS TO OR USE OF OUR SECURE SERVERS AND /
                        OR ANY AND ALL PERSONAL INFORMATION STORED
                        THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF
                        TRANSMISSION TO OR FROM THE SERVICES; (V) ANY BUGS,
                        VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE
                        TRANSMITTED TO OR THROUGH OUR SERVICES BY ANY THIRD
                        PARTY; (VI) ANY ERRORS OR OMISSIONS IN ANY MATERIALS
                        OR FOR ANY LOSS OR DAMAGE INCURRED AS A RESULT OF THE
                        USE OF ANY MATERIALS POSTED, EMAILED, TRANSMITTED, OR
                        OTHERWISE MADE AVAILABLE THROUGH THE
                        SERVICES; OR(VII) USER MATERIALS OR
                        THE DEFAMATORY, OFFENSIVE, OR ILLEGAL
                        CONDUCT OF ANY THIRD PARTY.THE AGGREGATE
                        LIABILITY OF THE NAXXA COMPANIES AND ANY
                        OF OUR AFFILIATES TO YOU FOR ALL CLAIMS
                        ARISING OUT OF OR RELATING TO THE USE OF,
                        OR INABILITY TO USE, ANY PORTION OF THE
                        SERVICES OR OTHERWISE UNDER THESE TERMS
                        OF USE, WHETHER UNDER CONTRACT, TORT, OR
                        OTHERWISE, IS LIMITED TO THE GREATER OF: (1) THE
                        AMOUNT YOU HAVE PAID TO THE NAXXA COMPANIES FOR
                        THE SERVICES IN THE 12 MONTHS PRIOR TO THE
                        EVENTS OR CIRCUMSTANCES GIVING RISE TO THE
                        CLAIMS; OR(2) $100.SOME JURISDICTIONS DO
                        NOT ALLOW THE EXCLUSION OR LIMITATION OF
                        LIABILITY FOR CONSEQUENTIAL DAMAGES.ACCORDINGLY,
                        THE ABOVE LIMITATIONS MAY NOT APPLY TO
                        YOU.EACH PROVISION OF THESE TERMS OF USE
                        THAT PROVIDES FOR A LIMITATION OF LIABILITY,
                        DISCLAIMER OF WARRANTIES, OR EXCLUSION OF
                        DAMAGES IS INTENDED TO AND DOES ALLOCATE THE
                        RISKS BETWEEN THE PARTIES UNDER THESE TERMS.
                        THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE
                        AGREEMENT OF THE PARTIES.THE LIMITATIONS IN
                        THIS SECTION WILL APPLY EVEN IF ANY LIMITED
                        REMEDY FAILS ITS ESSENTIAL PURPOSE.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        17. Choice of Law; Disputes.
                    </span>
                        These Terms of Use are governed by the laws
                        of the State of Washington, without giving
                        effect to its conflict of laws provisions.
                        You agree to submit to the personal and
                        exclusive jurisdiction and venue in the
                        state and federal courts sitting in King
                        County, Washington for all disputes, claims,
                        and actions arising from or in connection
                        with the Services or otherwise under these
                        Terms of Use.The Naxxa Companies operate
                        the Services from our offices in Washington,
                        and we make no representation that the
                        Services are appropriate or available for
                        use in other locations.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        18. General.
                    </span>
                        You agree not to export from anywhere
                        any part of the Services provided to you,
                        or any direct product thereof, except in
                        compliance with, and with all licenses and
                        approvals required under, applicable export
                        laws, rules and regulations.All Services used
                        by the U.S.Government are provided with the
                        commercial license rights described herein.
                        These Terms of Use may be amended only by a
                        written agreement signed by authorized
                        representatives of the parties to these
                        Terms of Use.If any part of these Terms
                        of Use is determined to be invalid or
                        unenforceable, then the invalid or unenforceable
                        provision will be replaced with a valid,
                        enforceable provision that most closely
                        matches the intent of the original
                        provision and the remainder of these
                        Terms of Use will continue in effect.
                        The section titles in these Terms of Use
                        are used solely for the convenience of the
                        parties and have no legal or contractual
                        significance.We may assign this Agreement,
                        in whole or in part, at any time with or without
                        notice to you.You may not assign these
                        Terms of Use, or assign, transfer or
                        sublicense your rights, if any, in the
                        Services.Our failure to act with respect
                        to a breach, or our choice to otherwise
                        waive breach by you or others, does not
                        waive our right to act with respect to
                        subsequent or similar breaches.Except
                        as expressly stated herein, these Terms
                        of Use, and all expressly incorporated
                        terms and agreements, constitute the
                        entire agreement between you and the
                        Naxxa Companies with respect to the
                        Services and supersede all prior or
                        contemporaneous communications of any
                        kind between you and the Naxxa Companies
                        with respect to the Services.The following
                        sections of these Terms of Use
                        shall survive any termination
                        of these Terms of Use: 3, 5 -
                        8, 10 - 12, and 14 - 22.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        19. Consent to Communications.
                    </span>
                        By using the Services, you consent to
                        receiving certain electronic communications
                        from us as further described in the Privacy
                        Policy.Please read our Privacy Policy to
                        learn more.You agree that any notices,
                        agreements, disclosures, surveys, or
                        other communications that we send to
                        you electronically will satisfy any
                        legal communication requirements,
                        including that those communications
                        be in writing.
                    </p>

                    <p>
                        The Services may provide web forms,
                        links, or contact information, including
                        phone numbers, that can connect you with
                        the Naxxa Companies or third parties,
                        such as real estate agents and Third -
                        Party Providers.Communications through
                        these methods may be routed through
                        a third - party service(“Communications
                        Service”).Calls may be recorded or monitored
                        for quality assurance, training, or customer
                        service purposes.You will be notified at
                        the beginning of a call if it may be
                        recorded or monitored.You consent to
                        such recording and monitoring by the Naxxa
                        Companies or the Communications
                        Service.We also use the Communications
                        Service to track phone calls and text
                        messages between you and real estate
                        professionals so that we and the real
                        estate professional can access certain
                        details about the contact.As part of
                        this process, we and the Communications
                        Service will receive in real time, and
                        store, data about your call or text message,
                        including the date and time of the call or
                        text message, your phone number, and the
                        content of the text message.You consent
                        to these practices by us and the
                        Communications Service.The information
                        is subject to the Privacy Policy.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        20. Notice to California Residents.
                    </span>
                        If you are a California resident,
                        under California Civil Code Section
                        1789.3, you may contact the Complaint
                        Assistance Unit of the Division of Consumer
                        Services of the California Department of
                        Consumer Affairs in writing at 1625 N.Market
                        Blvd., Suite S - 202, Sacramento, California
                        95834, or by telephone at(800) 952 - 5210
                        in order to resolve a complaint regarding
                        the Service or to receive further information
                        regarding use of the Service.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        21. Contact Information and License Disclosures.
                    </span>
                        The Services are offered by Naxxa,
                        Inc.and its affiliates, located at
                        1301 Second Avenue, Floor 36, Seattle,
                        WA 98101. You may contact the Naxxa Companies
                        by sending correspondence to that address or
                        emailing consumercare @Naxxa.com.To see
                        a complete list of Naxxa, Inc.’s real
                        estate brokerage licenses, see Real
                        Estate Licenses.
                    </p>
                    <p>
                        Naxxa Group Marketplace, Inc.is a wholly -
                        owned subsidiary of Naxxa, Inc.and has
                        its headquarters at 1301 Second Ave.,
                        30th floor, Suite 3000 - A, Seattle,
                        Washington 98101. The Nationwide Mortgage
                        Licensing System(NMLS) Identification Number
                        for Naxxa Group Marketplace, Inc.is 1303160.
                        To see a complete list of Naxxa
                        Group Marketplace, Inc.’s licenses,
                        see Licensing and Disclosures,
                        EQUAL HOUSING OPPORTUNITY.

                    </p>
                    <p>
                        Naxxa Home Loans, LLC is a wholly -
                        owned subsidiary of Naxxa, Inc., and
                        has its headquarters at 10975 El
                        Monte, Overland Park, KS 66211.
                        The NMLS Identification Number
                        for Naxxa Home Loans, LLC is 10287.
                        To see a complete list of Naxxa Home
                        Loans, LLC’s licenses, see this
                        Licensing page.Naxxa HOME LOANS,
                        LLC IS AN EQUAL HOUSING LENDER.

                    </p>
                    <p> Naxxa Closing Services, LLC, Naxxa
                        Closing Services AZNV, LLC and Naxxa
                        Closing Services TX, LLC are wholly -
                        owned subsidiaries of Naxxa Group, Inc.
                        Licensing information for the Naxxa Closing
                        Services entities can be found here.
                    </p>
                    <p><span style={{ fontWeight: 'bold' }}>
                        22. Notice to Apple Users.
                    </span>If you are using our mobile applications
                        on an iOS device, the terms of this Section 22
                        apply.You acknowledge that these Terms of Use
                        are between you and the Naxxa Companies only,
                        not with Apple, and Apple is not responsible for
                        the Services or related Materials.Apple has no
                        obligation to furnish any maintenance or support
                        services with respect to the Services.If the
                        Services fail to conform to any applicable warranty,
                        you may notify Apple and Apple will refund any
                        applicable purchase price for the mobile application
                        to you; to the maximum extent permitted by
                        applicable law, Apple has no other warranty
                        obligation with respect to the Services
                        .Apple is not responsible for addressing
                        any claim by you or any third party
                        relating to the Services or your possession
                        or use of the Services, including: (a)
                        product liability claims; (b) any claim
                        that the Services fail to conform to any
                        applicable legal or regulatory requirement;
                        and(c) claims arising under consumer
                        protection or similar legislation.Apple
                        is not responsible for the investigation,
                        defense, settlement or discharge of any third -
                        party claim that the Services or your
                        possession and use of the mobile application
                        infringe that third party’s intellectual
                        property rights.You agree to comply with
                        any applicable third - party terms when
                        using the Services.Apple and Apple’s
                        subsidiaries are third party beneficiaries
                        of Section 22 of these Terms of Use, and
                        upon your acceptance of these Terms or Use,
                        Apple will have the right(and will be
                        deemed to have accepted the right) to
                        enforce this Section 22 of these Terms
                        of Use against you.You hereby represent
                        and warrant that: (i) you are not located in
                        a country that is subject to a U.S.Government
                        embargo, or that is on Title 15, Part 740
                        Supplement 1, Country Group E of the U.S.Code
                        of Federal Regulations; and(ii) you are not
                        listed on any U.S.Government list of prohibited
                        or restricted parties.
                    </p>
                </Col>
            </Row>
        </div >
    )
}
