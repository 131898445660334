import { Row, Col, Modal, Empty } from 'antd';
import { useEffect, useState } from 'react';

export interface CompanyMoreInformationProps {
  data: string;
  isModalVisible: boolean;
  setIsModalVisible: (visible: boolean) => void;
}
export const CompanyMoreInformation = ({ isModalVisible, setIsModalVisible, data }:
  CompanyMoreInformationProps) => {

  const [companyData, setCompanyData] = useState<any>()


  useEffect(() => {
    if (data) {
      setCompanyData(data)
    }
  }, [data])

  const handleOk = () => {
    setIsModalVisible(false);
    setCompanyData(null)
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCompanyData(null)
  };

  return (
    <Modal
      title="Company"
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={900}
      style={{ marginTop: 50 }}
      bodyStyle={{ maxHeight: 700, overflowY: 'scroll', fontSize: 13 }}
    >
      {companyData ?
        <>
          <div className="meta-detail">
            <span className="meta-detail-name">Name: </span>
            <span className="meta-detail-value">
              {companyData.name}
            </span>
          </div>

          <Row>
            {companyData.email ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Email: </span>
                  <span className="meta-detail-value">{companyData.email}</span>
                </div>
              </Col>
            ) : null}
            {companyData.address ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Address: </span>
                  <span className="meta-detail-value">{companyData.address}</span>
                </div>
              </Col>
            ) : null}
            {companyData.mailingAddress ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Mailing Address: </span>
                  <span className="meta-detail-value">{companyData.mailingAddress}</span>
                </div>
              </Col>
            ) : null}
            {companyData.builderFocusSpecialty ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Builder Focus Specialty:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.builderFocusSpecialty}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.country ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Country: </span>
                  <span className="meta-detail-value">{companyData.country}</span>
                </div>
              </Col>
            ) : null}
            {companyData.city ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">City: </span>
                  <span className="meta-detail-value">{companyData.city}</span>
                </div>
              </Col>
            ) : null}
            {companyData.county ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">County: </span>
                  <span className="meta-detail-value">{companyData.county?.join(', ')}</span>
                </div>
              </Col>
            ) : null}
            {companyData.zip ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Zip: </span>
                  <span className="meta-detail-value">{companyData.zip}</span>
                </div>
              </Col>
            ) : null}
            {companyData.company ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Company: </span>
                  <span className="meta-detail-value">{companyData.company}</span>
                </div>
              </Col>
            ) : null}
            {companyData.contactType ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Contact Type: </span>
                  <span className="meta-detail-value">
                    {companyData.contactType && companyData.contactType
                      .toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.createdBy ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Created By: </span>
                  <span className="meta-detail-value">
                    {companyData.createdBy}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.dateOfBirth ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Date Of Birth: </span>
                  <span className="meta-detail-value">
                    {companyData.dateOfBirth}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.endProductPriceRanges ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    End Product Price Ranges:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.endProductPriceRanges}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.website ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Website: </span>
                  <span className="meta-detail-value">{companyData.website}</span>
                </div>
              </Col>
            ) : null}{' '}
            {companyData.facebookUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Facebook Url: </span>
                  <span className="meta-detail-value">
                    {companyData.facebookUrl}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.twitterUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Twitter Url: </span>
                  <span className="meta-detail-value">
                    {companyData.twitterUrl}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.fax ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Fax: </span>
                  <span className="meta-detail-value">{companyData.fax}</span>
                </div>
              </Col>
            ) : null}
            {companyData.garageSize ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Garage Size: </span>
                  <span className="meta-detail-value">
                    {companyData.garageSize}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.homeBuyerPriceRange ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Home Buyer Price Range:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.homeBuyerPriceRange}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.howManyAcresIsYourLand ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    How Many Acres Is Your Land:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.howManyAcresIsYourLand}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.howManyBeds ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">How Many Beds: </span>
                  <span className="meta-detail-value">
                    {companyData.howManyBeds}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.hsLeadStatus ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Hs Lead Status: </span>
                  <span className="meta-detail-value">
                    {companyData.hsLeadStatus && companyData.hsLeadStatus
                      .toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.investmentRange ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Investment Range: </span>
                  <span className="meta-detail-value">
                    {companyData.investmentRange.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.jobtitle ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Job Title: </span>
                  <span className="meta-detail-value">{companyData.jobtitle}</span>
                </div>
              </Col>
            ) : null}
            {companyData.landOwnerGeneralZoningQuestion ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Land Owner General Zoning Question:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.landOwnerGeneralZoningQuestion}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.marketSellBulk ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Market Sell Bulk: </span>
                  <span className="meta-detail-value">
                    {companyData.marketSellBulk}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.mobilephone ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Mobile Phone: </span>
                  <span className="meta-detail-value">
                    {companyData.mobilephone}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.phone ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Phone: </span>
                  <span className="meta-detail-value">{companyData.phone}</span>
                </div>
              </Col>
            ) : null}
            {companyData.phoneNumber1 ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Phone Number1: </span>
                  <span className="meta-detail-value">
                    {companyData.phoneNumber1}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.phoneNumber2 ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Phone Number2: </span>
                  <span className="meta-detail-value">
                    {companyData.phoneNumber2}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.projects ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Projects: </span>
                  <span className="meta-detail-value">
                    {companyData.projects && companyData.projects.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.websiteProjectsUrl ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Website Projects Url:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.websiteProjectsUrl &&
                      companyData.websiteProjectsUrl.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.realEstateLicenseTypeAndFocus ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Real Estate License Type And Focus:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.realEstateLicenseTypeAndFocus &&
                      companyData.realEstateLicenseTypeAndFocus.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.state ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">State: </span>
                  <span className="meta-detail-value">
                    {companyData.state && companyData.state.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.tempLandOwnerNumberAndStyleOfLots ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Temp Land Owner Number And Style Of Lots:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.tempLandOwnerNumberAndStyleOfLots &&
                      companyData.tempLandOwnerNumberAndStyleOfLots.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.typeStyleOfHome ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Type Style Of Home: </span>
                  <span className="meta-detail-value">
                    {companyData.typeStyleOfHome &&
                      companyData.typeStyleOfHome.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.utahAreaInvestmentTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Utah Area Investment Tags:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.utahAreaInvestmentTags &&
                      companyData.utahAreaInvestmentTags.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.vendorId ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Vendor Id: </span>
                  <span className="meta-detail-value">{companyData.vendorId}</span>
                </div>
              </Col>
            ) : null}
            {companyData.newBuildHomeBuyerDesiredCities ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    New Build Home Buyer Desired Cities:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.newBuildHomeBuyerDesiredCities}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.numberAndStyleOfLotsPurchased ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Number And Style Of Lots Purchased:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.numberAndStyleOfLotsPurchased}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.numberOfBathrooms ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    Number Of Bathrooms:{' '}
                  </span>
                  <span className="meta-detail-value">
                    {companyData.numberOfBathrooms}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.ownerTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Owner Tags: </span>
                  <span className="meta-detail-value">
                    {companyData.ownerTags}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.utahAreaTags?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Utah Area Tags: </span>
                  <span className="meta-detail-value">
                    {companyData.utahAreaTags && companyData.utahAreaTags
                      .toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.parcels?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Parcels: </span>
                  <span className="meta-detail-value">
                    {companyData.parcels?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.Type?.length ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Company Type: </span>
                  <span className="meta-detail-value">
                    {companyData.Type?.join(', ')}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.developerTags ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">Developer Tags: </span>
                  <span className="meta-detail-value">
                    {companyData.developerTags &&
                      companyData.developerTags.toString()}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.whatRealEstateProductDoesYourCompanyInvestIn ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    What Real Estate Product Does Your Company Invest In:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.whatRealEstateProductDoesYourCompanyInvestIn}
                  </span>
                </div>
              </Col>
            ) : null}
            {companyData.whatSizeOfLotDoYouWant ? (
              <Col span={12}>
                <div className="meta-detail">
                  <span className="meta-detail-name">
                    What Size Of Lot Do You Want:
                  </span>
                  <span className="meta-detail-value">
                    {companyData.whatSizeOfLotDoYouWant}
                  </span>
                </div>
              </Col>
            ) : null}
          </Row>
        </> : <div className="popupEmptyMsg">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>}
    </Modal>
  );
};

export default CompanyMoreInformation;
