/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Drawer, Empty, Row, Select, Spin, Table, Tabs, Typography } from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import { useGetParcelStatsFullValuationQuery, useGetParcelStatsSummaryQuery } from 'app/services'
import DownloadButton from 'components/DownloadExcel/DownloadButton'
import { MarketStatsDownload } from 'components/DownloadExcel/MarketStatsDownload'
import { useEffect, useState } from 'react'
import { CITIESFORSEARCH, stringifySearchObject } from 'utils'
import MarketStatsViewQuery from './MarketStatsViewQuery'

const MarketStats = ({ isStatsModalVisible, setIsStatsModalVisible, title }: any) => {

   const Tab = ({ property }: any) => (
      <div className="tab-title">
         <div className="name">
            <span>
               {property}
            </span>
            {/* {!count && <span>{camelCaseToWords(property)}</span>} */}
         </div>
      </div>
   );

   const [showQueryDrawer, setShowQueryDrawer] = useState<any>(false)
   const [statsDataFilters, setStatsDataFilters] = useState<any>({})
   const [statsSummaryData, setStatsSummaryData] = useState<any>(null)
   const [fullValuationDownloadData, setFullValuationDownloadData] = useState<any>({})
   const [activeNewBuildsData, setActiveNewBuildsData] = useState<any>([])
   const [activeNewBuildsColumns, setActiveNewBuildsColumns] = useState<any>(null)
   const [underContractNewBuildsData, setUnderContractNewBuildsData] = useState<any>([])
   const [underContractNewBuildsColumns, setUnderContractNewBuildsColumns] = useState<any>(null)
   const [soldNewBuildsData, setSoldNewBuildsData] = useState<any>([])
   const [soldNewBuildsColumns, setSoldNewBuildsColumns] = useState<any>(null)
   const [otherNewBuildsData, setOtherNewBuildsData] = useState<any>([])
   const [otherNewBuildsColumns, setOtherNewBuildsColumns] = useState<any>(null)
   const [activeExistingBuildsData, setActiveExistingBuildsData] = useState<any>([])
   const [activeExistingBuildsColumns, setActiveExistingBuildsColumns] = useState<any>(null)
   const [underContractExistingBuildsData, setUnderContractExistingBuildsData] = useState<any>([])
   const [underContractExistingBuildsColumns, setUnderContractExistingBuildsColumns] = useState<any>(null)
   const [soldExistingBuildsData, setSoldExistingBuildsData] = useState<any>([])
   const [soldExistingBuildsColumns, setSoldExistingBuildsColumns] = useState<any>(null)
   const [otherExistingBuildsData, setOtherExistingBuildsData] = useState<any>([])
   const [otherExistingBuildsColumns, setOtherExistingBuildsColumns] = useState<any>(null)
   const [activeAllBuildsData, setActiveAllBuildsData] = useState<any>([])
   const [activeAllBuildsColumns, setActiveAllBuildsColumns] = useState<any>(null)
   const [underContractAllBuildsData, setUnderContractAllBuildsData] = useState<any>([])
   const [underContractAllBuildsColumns, setUnderContractAllBuildsColumns] = useState<any>(null)
   const [soldAllBuildsData, setSoldAllBuildsData] = useState<any>([])
   const [soldAllBuildsColumns, setSoldAllBuildsColumns] = useState<any>(null)
   const [otherAllBuildsData, setOtherAllBuildsData] = useState<any>([])
   const [otherAllBuildsColumns, setOtherAllBuildsColumns] = useState<any>(null)
   const onFilterChange = (filter: any, value: any) => {
      setStatsDataFilters({ ...statsDataFilters, [filter]: value })
   }

   const {
      data: ParcelStatsSummary,
      isSuccess: isParcelStatsSummarySuccess,
      isFetching: isParcelStatsSummaryFetching,
   } = useGetParcelStatsSummaryQuery(`?${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: ActiveNewBuilds,
      isSuccess: isActiveNewBuildsSuccess,
      isFetching: isActiveNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=New Builds&status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: UnderContractNewBuilds,
      isSuccess: isUnderContractNewBuildsSuccess,
      isFetching: isUnderContractNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=New Builds&status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: SoldNewBuilds,
      isSuccess: isSoldNewBuildsSuccess,
      isFetching: isSoldNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=New Builds&status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: OtherNewBuilds,
      isSuccess: isOtherNewBuildsSuccess,
      isFetching: isOtherNewBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=New Builds&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });
   const {
      data: ActiveExistingBuilds,
      isSuccess: isActiveExistingBuildsSuccess,
      isFetching: isActiveExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=Existing Builds&status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: UnderContractExistingBuilds,
      isSuccess: isUnderContractExistingBuildsSuccess,
      isFetching: isUnderContractExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=Existing Builds&status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: SoldExistingBuilds,
      isSuccess: isSoldExistingBuildsSuccess,
      isFetching: isSoldExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=Existing Builds&status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: OtherExistingBuilds,
      isSuccess: isOtherExistingBuildsSuccess,
      isFetching: isOtherExistingBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?buildingType=Existing Builds&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: ActiveAllBuilds,
      isSuccess: isActiveAllBuildsSuccess,
      isFetching: isActiveAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?status=Active&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: UnderContractAllBuilds,
      isSuccess: isUnderContractAllBuildsSuccess,
      isFetching: isUnderContractAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?status=Under Contract&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: SoldAllBuilds,
      isSuccess: isSoldAllBuildsSuccess,
      isFetching: isSoldAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?status=Sold&${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   const {
      data: OtherAllBuilds,
      isSuccess: isOtherAllBuildsSuccess,
      isFetching: isOtherAllBuildsFetching,
   } = useGetParcelStatsFullValuationQuery(`?${stringifySearchObject(statsDataFilters)}`, {
      refetchOnReconnect: true,
      skip: !isStatsModalVisible
   });

   useEffect(() => {
      if (isParcelStatsSummarySuccess && ParcelStatsSummary?.length) {
         setStatsSummaryData(ParcelStatsSummary[0])
      }
   }, [ParcelStatsSummary, isParcelStatsSummarySuccess])
   const normalizeKey = (key: any) => key.replace(/[\s/]/g, "_");
   const toReadableText = (str: any) => {
      return str
         .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space before capital letters
         .replace(/_/g, " ") // Replace underscores with spaces
         .replace(/\b\w/g, (char: any) => char.toUpperCase()); // Capitalize each word
   };

   const constructDataAndColumns = (data: any) => {
      // Create columns
      const columns = [
         {
            title: "",
            dataIndex: "metric",
            key: "metric",
            width: 150,
            fixed: 'left'
         },
         ...data.map((item: any) => ({
            title: item.architecturalstyle,
            dataIndex: normalizeKey(item.architecturalstyle), // Normalize dataIndex
            key: normalizeKey(item.architecturalstyle),
            width: 150
         })),
      ];

      // Extract all field names except architecturalstyle
      const allFields = Object.keys(data[0]).filter(
         (key) => key !== "architecturalstyle"
      );

      // Create dataSource
      const dataSource = allFields.map((field, index) => ({
         key: index,
         metric: toReadableText(field), // Human-readable
         ...data.reduce((acc: any, item: any) => {
            const value = parseFloat(item[field]); // Parse as float
            acc[normalizeKey(item.architecturalstyle)] = isNaN(value)
               ? item[field] // Keep non-numeric values as-is
               : value.toFixed(2); // Round to 2 decimal places
            return acc;
         }, {}),
      }));
      return { columns, dataSource }
   }

   useEffect(() => {
      if (isActiveNewBuildsSuccess && ActiveNewBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(ActiveNewBuilds)
         // Update state
         setActiveNewBuildsColumns(columns);
         setActiveNewBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, activeNewBuilds: ActiveNewBuilds })
      }
   }, [ActiveNewBuilds, isActiveNewBuildsSuccess]);

   useEffect(() => {
      if (isUnderContractNewBuildsSuccess && UnderContractNewBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(UnderContractNewBuilds)
         // Update state
         setUnderContractNewBuildsColumns(columns);
         setUnderContractNewBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, underContractNewBuilds: UnderContractNewBuilds })
      }
   }, [UnderContractNewBuilds, isUnderContractNewBuildsSuccess]);

   useEffect(() => {
      if (isSoldNewBuildsSuccess && SoldNewBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(SoldNewBuilds)
         // Update state
         setSoldNewBuildsColumns(columns);
         setSoldNewBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, soldNewBuilds: SoldNewBuilds })
      }
   }, [UnderContractNewBuilds, isSoldNewBuildsSuccess]);

   useEffect(() => {
      if (isOtherNewBuildsSuccess && OtherNewBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(OtherNewBuilds)
         // Update state
         setOtherNewBuildsColumns(columns);
         setOtherNewBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, otherNewBuilds: OtherNewBuilds })
      }
   }, [OtherNewBuilds, isOtherNewBuildsSuccess]);

   useEffect(() => {
      if (isActiveExistingBuildsSuccess && ActiveExistingBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(ActiveExistingBuilds)
         // Update state
         setActiveExistingBuildsColumns(columns);
         setActiveExistingBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, activeExistingBuilds: ActiveExistingBuilds })
      }
   }, [ActiveExistingBuilds, isActiveExistingBuildsSuccess]);

   useEffect(() => {
      if (isUnderContractExistingBuildsSuccess && UnderContractExistingBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(UnderContractExistingBuilds)
         // Update state
         setUnderContractExistingBuildsColumns(columns);
         setUnderContractExistingBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, underContractExistingBuilds: UnderContractExistingBuilds })
      }
   }, [UnderContractExistingBuilds, isUnderContractExistingBuildsSuccess]);

   useEffect(() => {
      if (isSoldExistingBuildsSuccess && SoldExistingBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(SoldExistingBuilds)
         // Update state
         setSoldExistingBuildsColumns(columns);
         setSoldExistingBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, soldExistingBuilds: SoldExistingBuilds })
      }
   }, [UnderContractExistingBuilds, isSoldExistingBuildsSuccess]);

   useEffect(() => {
      if (isOtherExistingBuildsSuccess && OtherExistingBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(OtherExistingBuilds)
         // Update state
         setOtherExistingBuildsColumns(columns);
         setOtherExistingBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, otherExistingBuilds: OtherExistingBuilds })
      }
   }, [OtherExistingBuilds, isOtherExistingBuildsSuccess]);

   useEffect(() => {
      if (isActiveAllBuildsSuccess && ActiveAllBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(ActiveAllBuilds)
         // Update state
         setActiveAllBuildsColumns(columns);
         setActiveAllBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, activeAllBuilds: ActiveAllBuilds })
      }
   }, [ActiveAllBuilds, isActiveAllBuildsSuccess]);

   useEffect(() => {
      if (isUnderContractAllBuildsSuccess && UnderContractAllBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(UnderContractAllBuilds)
         // Update state
         setUnderContractAllBuildsColumns(columns);
         setUnderContractAllBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, underContractAllBuilds: UnderContractAllBuilds })
      }
   }, [UnderContractAllBuilds, isUnderContractAllBuildsSuccess]);

   useEffect(() => {
      if (isSoldAllBuildsSuccess && SoldAllBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(SoldAllBuilds)
         // Update state
         setSoldAllBuildsColumns(columns);
         setSoldAllBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, soldAllBuilds: SoldAllBuilds })
      }
   }, [UnderContractAllBuilds, isSoldAllBuildsSuccess]);

   useEffect(() => {
      if (isOtherAllBuildsSuccess && OtherAllBuilds?.length) {
         const { columns, dataSource } = constructDataAndColumns(OtherAllBuilds)
         // Update state
         setOtherAllBuildsColumns(columns);
         setOtherAllBuildsData(dataSource);
         setFullValuationDownloadData({ ...fullValuationDownloadData, otherAllBuilds: OtherAllBuilds })
      }
   }, [OtherAllBuilds, isOtherAllBuildsSuccess]);

   return (
      <>
         <Drawer
            title={<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
               <div>{title}</div>
               <div>
                  <Button onClick={() => {
                     setShowQueryDrawer(true)
                  }}>View Query</Button>
                  <DownloadButton type="" onClick={() => MarketStatsDownload(ParcelStatsSummary, fullValuationDownloadData, `${title}`)} style={{ borderRadius: 7, marginRight: 7, marginLeft: 7 }} items={ParcelStatsSummary} />
               </div>
            </div>}
            placement="right"
            width={1200}
            onClose={() => setIsStatsModalVisible(false)}
            open={isStatsModalVisible}
            zIndex={1050}
         >
            <>
               <Row>
                  <Col span={8}>
                     <Select
                        showSearch
                        mode='multiple'
                        allowClear
                        placeholder="Select Cities"
                        optionFilterProp="children"
                        filterOption={(input: any, option: any) => (option?.label ?? '')?.toLowerCase().includes(input.toLowerCase())}
                        options={CITIESFORSEARCH}
                        style={{ width: '100%' }}
                        dropdownStyle={{ zIndex: 9999, width: '100%' }}
                        onChange={(e) => onFilterChange('cities', e)}
                     />
                  </Col>
                  <Col span={6}></Col>
                  <Col span={10}>
                     <DatePicker.RangePicker style={{ borderRadius: '50px' }}
                        onChange={(e, time) => onFilterChange('dates', time)} />
                  </Col>
               </Row>

               <Tabs style={{ marginTop: 15 }}>
                  <TabPane
                     tab={<Tab property="Summary" />}
                     key="Summary"
                  >
                     <Spin
                        style={{ marginTop: 25 }}
                        spinning={isParcelStatsSummaryFetching}
                        tip='Loading' size="large"
                     >
                        {statsSummaryData ?
                           <Row style={{ margin: '10px 5px' }}>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Average List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.average_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Average List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.average_list_price}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Average Lot Size: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.average_lot_size}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Average Sqft: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.avg_sqft}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">High List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.high_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">High List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.high_list_price}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Low List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.low_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Low List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.low_list_price}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Median List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.median_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Median List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.median_list_price}</span>
                                 </div>
                              </Col>

                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Q1 List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.q1_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Q1 List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.q1_list_price}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Q3 List Dom: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.q3_list_dom}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Q3 List Price: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.q3_list_price}</span>
                                 </div>
                              </Col>
                              <Col span={12}>
                                 <div className="meta-detail">
                                    <span className="meta-detail-name">Number of Listings: </span>
                                    <span className="meta-detail-value">{statsSummaryData?.number_of_listings}</span>
                                 </div>
                              </Col>
                           </Row> : !isParcelStatsSummaryFetching ? <div className="popupEmptyMsg">
                              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                           </div> : null}
                     </Spin>
                  </TabPane>
                  <TabPane
                     tab={<Tab property="Full Valuation" />}
                     key="Full Valuation"
                  >
                     <div>
                        <Typography.Title className='market-stats-title'>New Builds</Typography.Title>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Active New Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isActiveNewBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {activeNewBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={activeNewBuildsColumns} dataSource={activeNewBuildsData} /> : !isActiveNewBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Under Contract New Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isUnderContractNewBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {underContractNewBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={underContractNewBuildsColumns} dataSource={underContractNewBuildsData} /> : !isUnderContractNewBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Sold New Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isSoldNewBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {soldNewBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={soldNewBuildsColumns} dataSource={soldNewBuildsData} /> : !isSoldNewBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Canceled/Expired/Others New Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isOtherNewBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {otherNewBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={otherNewBuildsColumns} dataSource={otherNewBuildsData} /> : !isOtherNewBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                     </div>
                     <div style={{ marginTop: 30 }}>
                        <Typography.Title className='market-stats-title'>Existing Builds</Typography.Title>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Active Existing Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isActiveExistingBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {activeExistingBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={activeExistingBuildsColumns} dataSource={activeExistingBuildsData} /> : !isActiveExistingBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Under Contract Existing Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isUnderContractExistingBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {underContractExistingBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={underContractExistingBuildsColumns} dataSource={underContractExistingBuildsData} /> : !isUnderContractExistingBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Sold Existing Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isSoldExistingBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {soldExistingBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={soldExistingBuildsColumns} dataSource={soldExistingBuildsData} /> : !isSoldExistingBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Canceled/Expired/Others Existing Builds</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isOtherExistingBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {otherExistingBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={otherExistingBuildsColumns} dataSource={otherExistingBuildsData} /> : !isOtherExistingBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                     </div>
                     <div style={{ marginTop: 30 }}>
                        <Typography.Title className='market-stats-title'>All Data</Typography.Title>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Active</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isActiveAllBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {activeAllBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={activeAllBuildsColumns} dataSource={activeAllBuildsData} /> : !isActiveAllBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Under Contract</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isUnderContractAllBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {underContractAllBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={underContractAllBuildsColumns} dataSource={underContractAllBuildsData} /> : !isUnderContractAllBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Sold</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isSoldAllBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {soldAllBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={soldAllBuildsColumns} dataSource={soldAllBuildsData} /> : !isSoldAllBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                        <div style={{ marginBottom: 10 }}>
                           <Typography.Title className='market-stats-sub-title'>Canceled/Expired/Others</Typography.Title>
                           <Spin
                              style={{ marginTop: 25 }}
                              spinning={isOtherAllBuildsFetching}
                              tip='Loading' size="large"
                           >
                              {otherAllBuildsData ?
                                 <Table size='small' scroll={{ x: "max-content" }} pagination={false} columns={otherAllBuildsColumns} dataSource={otherAllBuildsData} /> : !isOtherAllBuildsFetching ? <div className="popupEmptyMsg">
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                 </div> : null}
                           </Spin>
                        </div>
                     </div>
                  </TabPane>
               </Tabs>
            </>
         </Drawer>
         <Drawer
            title="View Query"
            placement="right"
            width={1200}
            onClose={() => setShowQueryDrawer(false)}
            open={showQueryDrawer}
            zIndex={1005}
         >
            <MarketStatsViewQuery statsDataFilters={statsDataFilters} />
         </Drawer>
      </>
   )
}

export default MarketStats